import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import integerWithThousandSeparator from 'shared-utils/src/integerWithThousandSeparator';
import sleep from 'shared-utils/src/sleep';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import { getItem } from 'shared-utils/src/localStorage';

import Overlayer from '../../layout/Overlayer';

import {
  polylineStyle,
  polygonStyle,
  MIN_CIRCLE_RADIUS,
  INITIAL_CIRCLE_RADIUS,
  INITIAL_CIRCLE_RADIUS_RANGE_LIMIT,
  MAX_CIRCLE_RADIUS_RANGE_LIMIT_DEFAULT,
  MAX_CIRCLE_RADIUS_RANGE_LIMIT_SAY,
  DRAWING_ACTIONS_MAP,
  initialRadius,
  LS_FAVORITEADDRESSES,
  NO_LABEL,
} from '../../../constants';

import {
  getHighestPoint,
  getLowestPoint,
  getWesternmostPoint,
  getEasternmostPoint,
  mouseCoordsToLatLng,
  setMarkerContent,
  drawCircle,
  sliderValues,
  getBounds,

  pxToLatLng,
  latLngToPx,
  addressBaloon,
  getBboxPolygon,
} from '../../../helpers/gmaps';

import removeActiveShapes from '../../../helpers/removeActiveShapes';

import { getAddressNumber } from '../../../helpers/getAddress';

import useSaveAddress from '../../../helpers/useSaveAddress';
import useEditSavedAddress from '../../../helpers/useEditSavedAddress';

import Controls from './Controls';

const CircleTool = ({
  initialized,
  map,
  container,
  searchMode,
  setSearchMode,
  mobileOs,
  device,
  libraries,
  setPinpoint,
  drawingData,
  updateQuery,
  setDrawingData,
  setGeoFilters,
  getAvailableResults,
  columnsAreCollapsed,
  viewport,
  isSearchAroundYou,
  circleFromPos,
  openQbFiltersPanel,
  qbGoToSRP,
  searchArounProps,
  qbSSearchAction,
  controlsVariant,
  setSaveSearchDrawingData,
  isSafari,
  showYouGotAwayMsg,
  setShowYouGotAwayMsg,
  filters,
  getDrawingData,
  synchPreviousStepsActiveShape,
  setInitialShapeForPrevious,
  deleteDraftAndStopSearchMode,
  toggleDeleteDraftAndStopSearchMode,
  isShowSaveAddressDialogOpen,
  showSaveAddressDialog,
  isHandleAddressesModalOpen,
  showHandleAddressesModal,
  setShapeAddressBaloon,
  setShapeHasBeenDeleted,
}) => {
  const [isDrawing, setIsDrawing] = useState(!drawingData || drawingData.action !== DRAWING_ACTIONS_MAP.circle);
  const [hasDrawing, setHasDrawing] = useState(drawingData && drawingData.action === DRAWING_ACTIONS_MAP.circle);
  const MAX_CIRCLE_RADIUS_RANGE_LIMIT = isSearchAroundYou ? MAX_CIRCLE_RADIUS_RANGE_LIMIT_SAY : MAX_CIRCLE_RADIUS_RANGE_LIMIT_DEFAULT;
  const { channel, goToSrpFromSAY } = searchArounProps;

  const hasClickedRef = useRef(false);
  const hasDraggedRef = useRef(false);
  const clickCoordsRef = useRef(null);
  const moveCoordsRef = useRef({ x: 0, y: 0 });

  const polylineRef = useRef(null);
  const circleRef = useRef(null);

  const northernMostVertexRef = useRef(null);
  const southernMostVertexRef = useRef(null);
  const easternMostVertexRef = useRef(null);
  const westernMostVertexRef = useRef(null);

  const northernMarker = useRef(null);
  const northernDraggableMarker = useRef(null);
  const westernMarker = useRef(null);
  const westernDraggableMarker = useRef(null);
  const southernMarker = useRef(null);
  const southernDraggableMarker = useRef(null);
  const easternMarker = useRef(null);
  const easternDraggableMarker = useRef(null);
  const centerMarker = useRef(null);
  const centerMarkerIcon = useRef(null);
  const centerMsgMarker = useRef(null);
  const cardinalPointsMarkersRef = useRef(null);
  const deleteMarkerRef = useRef(null);
  const editMarkerRef = useRef(null);
  const addressBaloonRef = useRef(null);
  const chn = useRef(channel);

  const startEditingRef = useRef(false);

  const [centerFromInit] = useState(drawingData?.circle?.center || null);
  const [c, setCenter] = useState(null);
  const [r, setRadius] = useState(drawingData?.circle?.radius || INITIAL_CIRCLE_RADIUS);
  const [radiusMaxRange, setRadiusMaxRange] = useState(initialRadius(drawingData?.circle?.radius));
  const [radiusRange, setRadiusRange] = useState(sliderValues(100, radiusMaxRange, 10));
  const [availableResults, setAvailableResults] = useState(drawingData?.availableResults || { loading: false, value: 0 });
  const [availablePoi, setAvailablePoi] = useState(null);

  const availableResultsRef = useRef(availableResults);
  const filtersRef = useRef(filters);

  const radiusRef = useRef(r);
  const radiusMaxRangeRef = useRef(radiusMaxRange);
  const radiusRangeRef = useRef(radiusRange);

  const filtersToAddToQuery = useRef({});
  const createdFromSSearch = useRef(false);

  const deviceRef = useRef(device);

  const mapBoundsOnCreateShape = useRef(null);

  // creo una referenza interna di searchMode attivo per il listener del deleteCirle
  // che altrimenti non recepisce il possibile cambio di valore della prop
  const searchModeRef = useRef(searchMode);

  // Creo una referenza interna di searchAddressRef per poter rendere cliccabile 
  // il marker del centro del cerchio con gli ultimi dati aggiornati
  const searchAddressRef = useRef(drawingData?.searchAddress || null);

  const updateAvailableResults = (arObj) => {
    availableResultsRef.current = arObj;
    setAvailableResults(arObj);
  };

  const updateSearchAddressRef = (addr) => {
    searchAddressRef.current = addr;
  }

  const setDrawingDataAddress = (searchAddress) => {
    let newDData = {
      ...drawingData,
      searchAddress,
    };

    const newDDataKeys = Object.keys(newDData);

    if (newDDataKeys.length === 1 && newDDataKeys[0] === 'searchAddress' && !newDDataKeys.searchAddress) {
      newDData = null;
    }

    updateSearchAddressRef(searchAddress);

    setDrawingData(newDData);
  };

  const fetchAR = async ({ radius, center }) => {
    setAvailableResults({ loading: true, value: 0 });
    centerMsgMarker.current.content = setMarkerContent({ centerMsg: true, animated: true });

    const ar = await getAvailableResults({
      newLocations: [],
      newFilters: {
        ...filtersRef.current,
        ...channel && { channel: chn.current },
        geocircle: {
          circle: [
            {
              distance: radius,
              center: [center.lat(), center.lng()],
            },
          ],
        },
        q: null,
        geobounds: null,
        geopolygon: null,
      },
    });

    updateAvailableResults({ loading: false, value: ar.total });
    setAvailablePoi(ar.availablePoi);
    centerMsgMarker.current.content = setMarkerContent({ centerMsg: `${ar.total} risultati` });
  };

  // calcoliamo le coordinate dei punti cardinali sul cerchio
  const getCardinalPointsCoordinates = () => {
    northernMostVertexRef.current = getHighestPoint(polylineRef.current);
    southernMostVertexRef.current = getLowestPoint(polylineRef.current);
    westernMostVertexRef.current = getWesternmostPoint(polylineRef.current);
    easternMostVertexRef.current = getEasternmostPoint(polylineRef.current);

    const np = polylineRef.current.getPath().getArray()[northernMostVertexRef.current];
    const wp = polylineRef.current.getPath().getArray()[westernMostVertexRef.current];
    const sp = polylineRef.current.getPath().getArray()[southernMostVertexRef.current];
    const ep = polylineRef.current.getPath().getArray()[easternMostVertexRef.current];

    return {
      np,
      wp,
      sp,
      ep,
    };
  };

  // aggiorniamo stati e referenze al raggio del cerchio e ai vari range che servono allo slider
  const updateRadius = (val) => {
    radiusRef.current = val;
    setRadius(val);
  };

  const updateRadiusMaxRange = (val) => {
    radiusMaxRangeRef.current = val;
    setRadiusMaxRange(val);
  };

  const updateRadiusRange = (val) => {
    radiusRangeRef.current = val;
    setRadiusRange(val);
  };

  /** METODI ASSOCIATI AI VERTICI DEL CERCHIO */

  // cancella la crosshair di GMaps sull'advanced marker element
  const handleVertexMouseOver = (e) => {
    const crosshairContainer = e.target.offsetParent.offsetParent;
    if (crosshairContainer.nodeName === 'GMP-ADVANCED-MARKER' && !crosshairContainer.classList.contains('csa_mapsearch__draw__ghost')) {
      crosshairContainer.classList.add('csa_mapsearch__draw__ghost');
    }
  };

  // mentre si trascina un vertice
  const handleVertexDrag = ({ latLng }) => {
    const { core: { LatLng }, geometry: { spherical } } = libraries;

    // se il raggio del cerchio è già minore o uguale al minimo raggio possibile non facciamo niente
    if (radiusRef.current < MIN_CIRCLE_RADIUS) {
      return;
    }

    // se il raggio del cerchio è già maggiore o uguale al massimo raggio possibile non facciamo niente
    if (radiusRef.current > MAX_CIRCLE_RADIUS_RANGE_LIMIT) {
      return;
    }

    // recuperiamo le coordinate che rappresentano il centro del cerchio
    const center = circleRef.current.getCenter();

    // calcoliamo la distanza che intercorre tra il centro del cerchio ed il vertice che stiamo trascinando
    // ovvero il nuovo raggio del cerchio
    let dist = Math.floor(spherical.computeDistanceBetween(
      center,
      latLng,
    ));

    // se il nuovo raggio è minore rispetto al minimo raggio possibile, lo fissiamo al valore del minimo raggio possibile
    if (dist < MIN_CIRCLE_RADIUS) {
      dist = MIN_CIRCLE_RADIUS;
    }

    // se il nuovo raggio è maggiore rispetto al massimo raggio possibile, lo fissiamo al valore del massimo raggio possibile
    if (dist > MAX_CIRCLE_RADIUS_RANGE_LIMIT) {
      dist = MAX_CIRCLE_RADIUS_RANGE_LIMIT;
    }

    // aggiorniamo le dimensioni di cerchio e polyline
    circleRef.current.setRadius(dist);
    polylineRef.current.setPath(drawCircle(center, dist / 1609.344, 1, LatLng, 'handleVertexDrag 1'));


    // applichiamo la logica per aggiornare i valori dello slider, se necessario
    let newRadiusRange = radiusRangeRef.current;
    let newRadiusMaxRange = radiusMaxRangeRef.current;

    if (dist > newRadiusMaxRange) {
      newRadiusMaxRange = Math.min(MAX_CIRCLE_RADIUS_RANGE_LIMIT, newRadiusMaxRange * 2);
      newRadiusRange = sliderValues(100, newRadiusMaxRange, 10);
    } else if (dist < INITIAL_CIRCLE_RADIUS_RANGE_LIMIT && newRadiusMaxRange > INITIAL_CIRCLE_RADIUS_RANGE_LIMIT) {
      newRadiusMaxRange = INITIAL_CIRCLE_RADIUS_RANGE_LIMIT;
      newRadiusRange = sliderValues(100, newRadiusMaxRange, 10);
    }

    // ricaviamo il nuovo valore del raggio, legandolo ai valori dello slider
    const newIndex = newRadiusRange.indexOf(dist) > -1
      ? newRadiusRange.indexOf(dist)
      : newRadiusRange.filter(val => parseInt(val, 10) <= dist).length - 1;

    // aggiorniamo le dimensioni di cerchio e polyline
    circleRef.current.setRadius(newRadiusRange[newIndex]);
    polylineRef.current.setPath(drawCircle(center, newRadiusRange[newIndex] / 1609.344, 1, LatLng, 'handleVertexDrag 2'));

    // aggiorniamo lo stato relativo al valore del raggio
    updateRadius(newRadiusRange[newIndex]);

    // aggiorniamo i valori dello slider, se necessario
    if (newRadiusMaxRange !== radiusMaxRangeRef.current) {
      updateRadiusMaxRange(newRadiusMaxRange);
      updateRadiusRange(newRadiusRange);
    }

    // calcoliamo le nuove coordinate dei vertici e aggiorniamo la posizione dei markers
    const newCpc = getCardinalPointsCoordinates();

    northernMarker.current.position = {
      lat: newCpc.np.lat(),
      lng: newCpc.np.lng(),
    };

    westernMarker.current.position = {
      lat: newCpc.wp.lat(),
      lng: newCpc.wp.lng(),
    };

    southernMarker.current.position = {
      lat: newCpc.sp.lat(),
      lng: newCpc.sp.lng(),
    };

    easternMarker.current.position = {
      lat: newCpc.ep.lat(),
      lng: newCpc.ep.lng(),
    };

    deleteMarkerRef.current.position = {
      lat: newCpc.np.lat(),
      lng: newCpc.np.lng(),
    };
  };

  // quando si smette di trascinare un vertice
  const handleVertexDragEnd = () => {
    // riposizioniamo la mappa se necessario
    map.fitBounds(circleRef.current.getBounds());

    // aggiorniamo le posizioni dei markers draggabili
    northernDraggableMarker.current.position = northernMarker.current.position;
    westernDraggableMarker.current.position = westernMarker.current.position;
    southernDraggableMarker.current.position = southernMarker.current.position;
    easternDraggableMarker.current.position = easternMarker.current.position;
    fetchAR({
      radius: radiusRef.current,
      center: circleRef.current.getCenter(),
    });
  };

  // stampa i marker dei punti cardinali, draggabili e non
  const createCardinalPointMarker = (coords, isDraggable) => ({
    map,
    position: {
      lat: coords.lat(),
      lng: coords.lng(),
    },
    content: setMarkerContent({ vertex: !isDraggable, handle: isDraggable }),
    gmpDraggable: isDraggable,
    zIndex: isDraggable ? 3 : 2,
  });

  // crea i marker draggabili e non sui punti cardinali del cerchio
  const setVertexes = (cpc) => {
    const { marker: { AdvancedMarkerElement } } = libraries;

    northernMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.np, false));
    northernDraggableMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.np, true));
    northernDraggableMarker.current.addEventListener('mouseover', handleVertexMouseOver);
    northernDraggableMarker.current.addListener('drag', handleVertexDrag);
    northernDraggableMarker.current.addListener('dragend', handleVertexDragEnd);

    westernMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.wp, false));
    westernDraggableMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.wp, true));
    westernDraggableMarker.current.addEventListener('mouseover', handleVertexMouseOver);
    westernDraggableMarker.current.addListener('drag', handleVertexDrag);
    westernDraggableMarker.current.addListener('dragend', handleVertexDragEnd);

    southernMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.sp, false));
    southernDraggableMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.sp, true));
    southernDraggableMarker.current.addEventListener('mouseover', handleVertexMouseOver);
    southernDraggableMarker.current.addListener('drag', handleVertexDrag);
    southernDraggableMarker.current.addListener('dragend', handleVertexDragEnd);

    easternMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.ep, false));
    easternDraggableMarker.current = new AdvancedMarkerElement(createCardinalPointMarker(cpc.ep, true));
    easternDraggableMarker.current.addEventListener('mouseover', handleVertexMouseOver);
    easternDraggableMarker.current.addListener('drag', handleVertexDrag);
    easternDraggableMarker.current.addListener('dragend', handleVertexDragEnd);

    cardinalPointsMarkersRef.current = [
      northernMarker.current,
      northernDraggableMarker.current,
      westernMarker.current,
      westernDraggableMarker.current,
      southernMarker.current,
      southernDraggableMarker.current,
      easternMarker.current,
      easternDraggableMarker.current,
    ];
  };

  /** METODI ASSOCIATI AL MARKER AL CENTRO CERCHIO */
  const saveAddressSuccessAction = ({ favorite }) => {
    const [fav] = favorite;
    const { address, number, town, label, lat, lon } = fav;
    const baloonData = {
      address,
      location: {
        lat,
        lng: lon,
      },
      name: label && label !== NO_LABEL ? label : `${address}${number ? ` ${number}` : ''}`,
      number: number || '',
      parent: label && label !== NO_LABEL ? `${address}${number ? ` ${number}` : ''}, ${town}` : town,
      favorite: true,
    };

    addressBaloonRef.current.content = addressBaloon(baloonData, fav, manageFavoriteAddresses);
  };

  const editAddressSuccessAction = ({ pinConfiguration }) => {
    if (addressBaloonRef.current) {
      let baloonData = {
        name: pinConfiguration.name,
        parent: pinConfiguration.parent,
        favorite: pinConfiguration.favorite,
      };
      let favData = null;
      let action = saveAddress;
      if (pinConfiguration.favorite) {
        const latlngStr = getLatLngStr(pinConfiguration.lat, pinConfiguration.lon);
        const favArr = getItem(LS_FAVORITEADDRESSES) || [];
        favData = favArr.filter(f => latlngStr === getLatLngStr(f.lat, f.lon))[0];
        action = manageFavoriteAddresses;
      } else {
        const { address, number } = getAddressNumber(pinConfiguration);
        baloonData = {
          ...baloonData,
          address,
          number: number || '',
          location: {
            lat: pinConfiguration.lat,
            lng: pinConfiguration.lon,
          },
        };
      }

      addressBaloonRef.current.content = addressBaloon(baloonData, favData, action);
    }
  };

  const { saveAddress } = useSaveAddress({ saveAddressSuccessAction });
  const { manageFavoriteAddresses } = useEditSavedAddress({ map, libraries, editAddressSuccessAction });

  const getLatLngStr = (x, y) => `${x}-${y}`;

  const handleCenterMarkerClick = () => {
    if (!searchModeRef.current) {
      if (addressBaloonRef.current) {
        addressBaloonRef.current.setMap(null);
        addressBaloonRef.current = null;
      } else {
        const { core: { LatLng }, marker: { AdvancedMarkerElement } } = libraries;

        const { name, parent, number } = searchAddressRef.current;

        const lat = centerMarker.current.position.lat;
        const lng = centerMarker.current.position.lng;

        const { address, number: computedNumber } = getAddressNumber(searchAddressRef.current);
        const num = number || computedNumber;
  
        const [x, y] = latLngToPx(new LatLng(lat, lng), map);
        const mCoords = pxToLatLng(x, y - 32, map);

        let favorite = false;
        let favData = null;
        let baloonData = {
          address,
          location: {
            lat,
            lng,
          },
          name,
          number: num || '',
          parent,
          favorite: false,
        }

        const favArr = getItem(LS_FAVORITEADDRESSES) || [];
        if (favArr.length) {
          const latlngStr = getLatLngStr(lat, lng);
          favData = favArr.filter(f => latlngStr === getLatLngStr(f.lat, f.lon))[0];          
          if (favData) {
            favorite = true;
            baloonData.name = favData.label && favData.label !== NO_LABEL ? favData.label : `${favData.address}${favData.number ? ` ${favData.number}` : ''}`;
            baloonData.parent = favData.label && favData.label !== NO_LABEL ? `${favData.address}${favData.number ? ` ${favData.number}` : ''}, ${favData.town}` : favData.town;
            baloonData.favorite = true;
          }
        }

        const action = favorite ? manageFavoriteAddresses : saveAddress;

        addressBaloonRef.current = new AdvancedMarkerElement({
          map,
          position: mCoords,
          content: addressBaloon(baloonData, favData, action),
          zIndex: 503,
        });
      }

      setShapeAddressBaloon(addressBaloonRef.current);
    }
  };

  // mentre si trascina il marker
  const handleCenterMarkerDrag = (e) => {
    const { core: { LatLng } } = libraries;

    if (!centerMarker.current.classList.contains('csa_mapsearch__draw__ghost')) {
      centerMarker.current.classList.add('csa_mapsearch__draw__ghost');
    }

    polylineRef.current.setPath(drawCircle(e.latLng, radiusRef.current / 1609.344, 1, LatLng, 'handleCenterMarkerDrag'));
    circleRef.current.setCenter(e.latLng);

    centerMarkerIcon.current.position = e.latLng;
    centerMsgMarker.current.position = e.latLng;

    // calcoliamo le coordinate dei punti cardinali sul perimetro del cerchio
    const newCpc = getCardinalPointsCoordinates();

    // aggiorniamo la posizione del deleteMarker
    deleteMarkerRef.current.position = {
      lat: newCpc.np.lat(),
      lng: newCpc.np.lng(),
    };

    // solo su desktop aggiorniamo la posizione dei markers dei vertici
    if (deviceRef.current === 'desktop') {
      northernMarker.current.position = {
        lat: newCpc.np.lat(),
        lng: newCpc.np.lng(),
      };

      northernDraggableMarker.current.position = {
        lat: newCpc.np.lat(),
        lng: newCpc.np.lng(),
      };

      westernMarker.current.position = {
        lat: newCpc.wp.lat(),
        lng: newCpc.wp.lng(),
      };

      westernDraggableMarker.current.position = {
        lat: newCpc.wp.lat(),
        lng: newCpc.wp.lng(),
      };

      southernMarker.current.position = {
        lat: newCpc.sp.lat(),
        lng: newCpc.sp.lng(),
      };

      southernDraggableMarker.current.position = {
        lat: newCpc.sp.lat(),
        lng: newCpc.sp.lng(),
      };

      easternMarker.current.position = {
        lat: newCpc.ep.lat(),
        lng: newCpc.ep.lng(),
      };

      easternDraggableMarker.current.position = {
        lat: newCpc.ep.lat(),
        lng: newCpc.ep.lng(),
      };
    }
  };

  // quando si smette di trascinare il marker
  const handleCenterMarkerDragEnd = () => {
    map.fitBounds(circleRef.current.getBounds());
    const center = circleRef.current.getCenter();
    setCenter(center);
    fetchAR({
      radius: radiusRef.current,
      center,
    });
  };

  /* CANCELLAZIONE DEL CERCHIO */

  const deleteCircle = (updatingAddress = false) => {
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }

    if (circleRef.current) {
      circleRef.current.setMap(null);
      circleRef.current = null;
    }

    if (cardinalPointsMarkersRef.current) {
      cardinalPointsMarkersRef.current.map((v) => {
        v.setMap(null);
        v = null;
      });
      cardinalPointsMarkersRef.current = null;
    }
    if (centerMarker.current) {
      centerMarker.current.setMap(null);
      centerMarker.current = null;
    }
    if (centerMarkerIcon.current) {
      centerMarkerIcon.current.setMap(null);
      centerMarkerIcon.current = null;
    }
    if (centerMsgMarker.current) {
      centerMsgMarker.current.setMap(null);
      centerMsgMarker.current = null;
    }
    if (editMarkerRef.current) {
      editMarkerRef.current.setMap(null);
      editMarkerRef.current = null;
    }

    if (deleteMarkerRef.current) {
      deleteMarkerRef.current.setMap(null);
      deleteMarkerRef.current = null;
    }

    if (addressBaloonRef.current) {
      addressBaloonRef.curren.setMap(null);
      addressBaloonRef.current = null;
    }

    updateRadius(INITIAL_CIRCLE_RADIUS);
    updateRadiusMaxRange(INITIAL_CIRCLE_RADIUS_RANGE_LIMIT);
    updateRadiusRange(sliderValues(100, INITIAL_CIRCLE_RADIUS_RANGE_LIMIT, 10));

    if (!updatingAddress) {
      setCenter({ delete: true });
      setTimeout(() => setIsDrawing(true));
    }

    setHasDrawing(false);
    updateAvailableResults({ loading: true, value: 0 });
    // se non siamo in fase di disegno cancelliamo i drawing data e facciamo partire la query
    if (!searchModeRef.current) {
      const action = 'delete';
      const deleteShapedata = { ...getDrawingData(), fromBack: true };

      setDrawingData(null);
      if (drawingData && drawingData.action === DRAWING_ACTIONS_MAP.circle && drawingData.fromSSearch) {
        setSaveSearchDrawingData(null);
      }

      const newBounds = map.getBounds();

      updateQuery({
        geobounds: {
          bbox: getBounds(newBounds),
        },
        bboxPolygon: getBboxPolygon(newBounds),
        geocircle: null,
        q: null,
        resetToMapLocation: true,
      }, action, deleteShapedata);

      setShapeHasBeenDeleted(true);
    }
  };

  // creiamo il marker cliccabile che permette di tornare in modalità editing
  const createEditMarker = ({
    cardinalPointsCoordinates,
    circleCenter,
    sSearchRadius = null,
  }) => {
    const { marker: { AdvancedMarkerElement } } = libraries;
    editMarkerRef.current = new AdvancedMarkerElement({
      map,
      position: cardinalPointsCoordinates.sp,
      content: setMarkerContent({ edit: true, value: `${integerWithThousandSeparator(sSearchRadius || radiusRef.current)}m` }),
      zIndex: 1,
    });

    // al click sull'editMarker
    editMarkerRef.current.addListener('click', () => setEditingMode({
      cardinalPointsCoordinates,
      circleCenter,
    }));
  };

  // quando decidiamo che il cerchio ci va bene
  const confirmCircle = ({ searchAddress, clickFrom = 'QB_MainButton' }) => {
    // aggiorniamo lo stile della polyline
    polylineRef.current.setOptions(polylineStyle.complete);
    circleRef.current.setOptions(polygonStyle.complete);

    // calcoliamo le coordinate dei punti cardinali sul cerchio
    const cpc = getCardinalPointsCoordinates();

    // prendiamo il LatLng del centro del cerchio
    const cCenter = circleRef.current.getCenter();

    if (!editMarkerRef.current) {
      createEditMarker({
        cardinalPointsCoordinates: cpc,
        circleCenter: cCenter,
      });
    }

    // eliminiamo i marker sui punti cardinali
    if (cardinalPointsMarkersRef.current) {
      cardinalPointsMarkersRef.current.map((v) => {
        v.setMap(null);
        v = null;
      });
      cardinalPointsMarkersRef.current = null;
    }

    // eliminiamo il marker degli available results
    if (centerMsgMarker.current) {
      centerMsgMarker.current.setMap(null);
      centerMsgMarker.current = null;
    }

    centerMarker.current.content = setMarkerContent({ center: true, handle: true });
    centerMarker.current.gmpDraggable = false;
    centerMarker.current.removeEventListener('drag', handleCenterMarkerDrag);
    centerMarker.current.removeEventListener('dragend', handleCenterMarkerDragEnd);

    const newDrawingData = {
      searchAddress,
      availableResults: availableResultsRef.current,
      action: DRAWING_ACTIONS_MAP.circle,
      circle: {
        radius: radiusRef.current,
        center: [cCenter.lat(), cCenter.lng()],
      },
      activeShapes: [
        polylineRef.current,
        circleRef.current,
        deleteMarkerRef.current,
        centerMarker.current,
        centerMarkerIcon.current,
        editMarkerRef.current,
      ],
      shapeBounds: circleRef.current.getBounds(),
      from: DRAWING_ACTIONS_MAP.circle,
    };

    setPinpoint(null);
    setDrawingData(newDrawingData);

    newDrawingData.mapBoundsOnCreateShape = mapBoundsOnCreateShape.current;

    if (filtersRef.current.q) {
      newDrawingData.q = filtersRef.current.q;
    }

    setGeoFilters({
      newfilter: {
        geocircle: {
          circle: [
            {
              distance: radiusRef.current,
              center: [cCenter.lat(), cCenter.lng()],
            },
          ],
        },
        q: null,
        geobounds: null,
        geopolygon: null,
        nearby: null,
        ...filtersToAddToQuery.current,
      },
      resetLocations: true,
      action: 'draw',
      drawingData: newDrawingData,
      // searchAddress,
    });

    if (showYouGotAwayMsg) {
      setShowYouGotAwayMsg(false);
    }

    sleep(150).then(() => {
      trackGTMEvents({
        category: 'Interaction',
        action: 'SeeSearchResults',
        // searchType: 'geocircle',
        position: clickFrom,
      }, {}, true);
      setSearchMode(null);
    });   
  };

  // creiamo il marker che mostra il numero di risultati disponibili
  const createCenterMsgMarker = ({ circleCenter, animated = false }) => {
    const { marker: { AdvancedMarkerElement }, geocoding } = libraries;
    
    const mrkContent = animated
      ? { centerMsg: true, animated: true }
      : { centerMsg: `${availableResultsRef.current.value} risultati`, animated: false };
      
    centerMsgMarker.current = new AdvancedMarkerElement({
      map,
      position: {
        lat: circleCenter.lat(),
        lng: circleCenter.lng(),
      },
      content: setMarkerContent(mrkContent),
      zIndex: 502,
    });

    centerMsgMarker.current.addListener('click', async () => {
      if (isSearchAroundYou) {
        goToSrpFromSAY(channel, 'QB_MapLabel');
      } else if (qbGoToSRP) {
        qbGoToSRP({
          tempLocations: {
            searchLocations: [],
            searchLocationsParent: [],
          },
          shapeFilter: { 
            geocircle: { 
              circle: [{ 
                distance: radiusRef.current, 
                center: [
                  circleCenter.lat(), 
                  circleCenter.lng(),
                ],
              }],
            },
            q: null,
            geobounds: null,
            geopolygon: null,
            nearby: null,
          },
          forceMap: true,
        });
      } else {
        confirmCircle({ searchAddress: searchAddressRef.current, clickFrom: 'QB_MapLabel' });
      }
    });
  };

  const setEditingMode = ({
    cardinalPointsCoordinates,
    circleCenter,
  }) => {
    if (editMarkerRef.current) {
      editMarkerRef.current.setMap(null);
      editMarkerRef.current = null;

      // settiamo lo stile corretto alla polyline
      polylineRef.current.setOptions({ ...polylineStyle.editing });
      circleRef.current.setOptions({ ...polygonStyle.drawing });
      
      if (deviceRef.current === 'desktop') {
        // stampiamo i vertici che permettono di editare la figura
        setVertexes(cardinalPointsCoordinates);
      }

      centerMarker.current.content = setMarkerContent({ center: true, handle: true });
      centerMarker.current.gmpDraggable = true;

      centerMarker.current.addListener('drag', handleCenterMarkerDrag);
      centerMarker.current.addListener('dragend', handleCenterMarkerDragEnd);

      createCenterMsgMarker({ circleCenter });

      // comunichiamo che siamo in fase di editing
      startEditingRef.current = true;
      setSearchMode(DRAWING_ACTIONS_MAP.circle);
    }
  };


  // creiamo il deleteMarker sul punto più a nord del cerchio
  // non lo mettiamo per SAY
  const createDeleteMarker = (northernMostVertexPosition) => {
    const { marker: { AdvancedMarkerElement } } = libraries;
    deleteMarkerRef.current = new AdvancedMarkerElement({
      map,
      position: { lat: northernMostVertexPosition.lat(), lng: northernMostVertexPosition.lng() },
      content: setMarkerContent({ cancel: !isSearchAroundYou }),
      zIndex: 1,
    });
    deleteMarkerRef.current.addListener('click', () => deleteCircle());
  };

  // creiamo i 2 marker del centro del cerchio
  const createCenterMarker = ({ circleCenter }) => {
    const { marker: { AdvancedMarkerElement } } = libraries;

    // icona della bandiera (non cliccabile)
    centerMarkerIcon.current = new AdvancedMarkerElement({
      map,
      position: {
        lat: circleCenter.lat(),
        lng: circleCenter.lng(),
      },
      content: setMarkerContent({ center: true, handle: false }),
      gmpDraggable: false,
      zIndex: 502,
    });

    // ghost marker (cliccabile)
    centerMarker.current = new AdvancedMarkerElement({
      map,
      position: {
        lat: circleCenter.lat(),
        lng: circleCenter.lng(),
      },
      content: setMarkerContent({ center: true, handle: true }),
      gmpDraggable: false,
      zIndex: 503,
    });

    centerMarker.current.addEventListener('mouseover', handleVertexMouseOver);
    if (!Object.keys((circleFromPos || {})).length) {
      centerMarker.current.addListener('click', handleCenterMarkerClick);
    }    
    centerMarker.current.addListener('drag', handleCenterMarkerDrag);
    centerMarker.current.addListener('dragend', handleCenterMarkerDragEnd);
  };

  // annulliamo le modifiche e torniamo in modalità ricerca
  const revertCircle = () => {
    const {
      core: { LatLng },
      maps: { Polyline, Circle },
    } = libraries;

    const hasShape = !!circleRef.current;

    const currentCenter = hasShape
      ? circleRef.current.getCenter()
      : new LatLng({ lat: drawingData.circle.center[0], lng: drawingData.circle.center[1] });

    let rCenter = currentCenter;
    let rRadius = radiusRef.current;

    let centerHasChanged = false;
    let radiusChanged = false;

    if (currentCenter.lat() !== drawingData.circle.center[0] || currentCenter.lng() !== drawingData.circle.center[1]) {
      centerHasChanged = true;
      rCenter = new LatLng({ lat: drawingData.circle.center[0], lng: drawingData.circle.center[1] });
    }

    if (radiusRef.current !== drawingData.circle.radius) {
      radiusChanged = true;
      rRadius = drawingData.circle.radius;
    }

    if (centerHasChanged || radiusChanged || !hasShape) {
      // aggiorniamo coordinate, dimensioni e stile di cerchio e polyline
      if (!hasShape) {
        // creiamo la polyline con forma circolare
        polylineRef.current = new Polyline(polylineStyle.complete);
        polylineRef.current.setPath(drawCircle(rCenter, rRadius / 1609.344, 1, LatLng, 'revertCircle 1'));
        polylineRef.current.setMap(map);

        // creiamo il cerchio
        circleRef.current = new Circle(polygonStyle.complete);
        circleRef.current.setOptions({ center: rCenter, radius: rRadius });
        circleRef.current.setMap(map);
      } else {
        circleRef.current.setCenter(rCenter);
        circleRef.current.setRadius(rRadius);
        polylineRef.current.setPath(drawCircle(rCenter, rRadius / 1609.344, 1, LatLng, 'revertCircle 2'));
      }

      updateRadius(rRadius);

      let newRadiusMaxRange = radiusMaxRange;
      if (rRadius < INITIAL_CIRCLE_RADIUS_RANGE_LIMIT && radiusMaxRange > INITIAL_CIRCLE_RADIUS_RANGE_LIMIT) {
        newRadiusMaxRange = INITIAL_CIRCLE_RADIUS_RANGE_LIMIT;
      } else if (radiusRange.indexOf(rRadius) === radiusRange.length - 1) {
        newRadiusMaxRange = Math.min(MAX_CIRCLE_RADIUS_RANGE_LIMIT, radiusMaxRange * 2);
      }

      // aggiorniamo i possibili valori dello slider, se necessario
      if (newRadiusMaxRange !== radiusMaxRange) {
        updateRadiusMaxRange(newRadiusMaxRange);
        updateRadiusRange(sliderValues(MIN_CIRCLE_RADIUS, newRadiusMaxRange, 10));
      }

      updateAvailableResults(drawingData.availableResults);
      // map.fitBounds(circleRef.current.getBounds());
    }

    // calcoliamo le coordinate dei punti cardinali sul cerchio
    const cpc = getCardinalPointsCoordinates();

    if (!editMarkerRef.current) {
      createEditMarker({
        cardinalPointsCoordinates: cpc,
        circleCenter: rCenter,
      });
    }

    if (hasShape) {
      polylineRef.current.setOptions(polylineStyle.complete);

      deleteMarkerRef.current.position = {
        lat: cpc.np.lat(),
        lng: cpc.np.lng(),
      };

      centerMarkerIcon.current.position = {
        lat: rCenter.lat(),
        lng: rCenter.lng(),
      };

      centerMarker.current.content = setMarkerContent({ center: true, handle: true });
      centerMarker.current.gmpDraggable = false;
      centerMarker.current.removeEventListener('drag', handleCenterMarkerDrag);
      centerMarker.current.removeEventListener('dragend', handleCenterMarkerDragEnd);
    } else {
      createDeleteMarker(cpc.np);
      createCenterMarker({ circleCenter: rCenter });
    }

    // eliminiamo i marker sui punti cardinali
    if (cardinalPointsMarkersRef.current) {
      cardinalPointsMarkersRef.current.map((v) => {
        v.setMap(null);
        v = null;
      });
      cardinalPointsMarkersRef.current = null;
    }

    // eliminiamo il marker degli available results
    if (centerMsgMarker.current) {
      centerMsgMarker.current.setMap(null);
      centerMsgMarker.current = null;
    }
    setIsDrawing(false);
    setSearchMode(null);

    setDrawingData({
      ...drawingData,
      activeShapes: [
        polylineRef.current,
        circleRef.current,
        deleteMarkerRef.current,
        centerMarker.current,
        centerMarkerIcon.current,
        editMarkerRef.current,
      ],
      shapeBounds: circleRef.current.getBounds(),
    });
  };

  // aggiungiamo i filtri settati via QB alla query
  const updateFiltersToAddToMapQuery = ({ filtersQB, searchAddress, ar }) => {
    filtersToAddToQuery.current = { ...filtersToAddToQuery.current, ...filtersQB };
    updateAvailableResults(ar);
    confirmCircle({ searchAddress, ar, clickFrom: 'QB_MainButton' });
  };

  /** METODI ASSOCIATI ALLO SLIDER DI MODIFICA DEL RAGGIO */

  // mentre si interagisce con lo slider
  const updateCircleRadius = (radius) => {
    const { core: { LatLng } } = libraries;

    // aggiorniamo le dimensioni di cerchio e polyline
    circleRef.current.setRadius(radius);
    polylineRef.current.setPath(drawCircle(circleRef.current.getCenter(), radius / 1609.344, 1, LatLng, 'updateCircleRadius'));

    // calcoliamo le coordinate dei vertici
    const newCpc = getCardinalPointsCoordinates();

    // aggiorniamo la posizione del deleteMarker
    deleteMarkerRef.current.position = {
      lat: newCpc.np.lat(),
      lng: newCpc.np.lng(),
    };

    // solo su desktop aggiorniamo la posizione dei markers dei vertici
    if (deviceRef.current === 'desktop') {
      northernMarker.current.position = {
        lat: newCpc.np.lat(),
        lng: newCpc.np.lng(),
      };

      northernDraggableMarker.current.position = {
        lat: newCpc.np.lat(),
        lng: newCpc.np.lng(),
      };

      westernMarker.current.position = {
        lat: newCpc.wp.lat(),
        lng: newCpc.wp.lng(),
      };

      westernDraggableMarker.current.position = {
        lat: newCpc.wp.lat(),
        lng: newCpc.wp.lng(),
      };

      southernMarker.current.position = {
        lat: newCpc.sp.lat(),
        lng: newCpc.sp.lng(),
      };

      southernDraggableMarker.current.position = {
        lat: newCpc.sp.lat(),
        lng: newCpc.sp.lng(),
      };

      easternMarker.current.position = {
        lat: newCpc.ep.lat(),
        lng: newCpc.ep.lng(),
      };

      easternDraggableMarker.current.position = {
        lat: newCpc.ep.lat(),
        lng: newCpc.ep.lng(),
      };
    }

    // aggiorniamo il valore del raggio del cerchio
    updateRadius(radius);
  };

  // una volta terminata l'interazione con lo slider
  const afterUpdateCircleRadius = () => {
    // riposizioniamo la mappa se necessario
    map.fitBounds(circleRef.current.getBounds());

    /**
      lo slider ha sempre 100m come valore minimo 
      il valore massimo iniziale è 2000m
      ogni volta che si setta lo slider al suo massimo valore, questo viene raddioppiato sino a raggiungere un massimo di 64000m
      se si torna a settare un raggio inferiore ai 2000m ed il raggio all'inizio dell'interazione è superiore ai 2000m, 
      il valore massimo torna ad essere 2000m
     */
    let newRadiusMaxRange = radiusMaxRange;
    if (r < INITIAL_CIRCLE_RADIUS_RANGE_LIMIT && radiusMaxRange > INITIAL_CIRCLE_RADIUS_RANGE_LIMIT) {
      newRadiusMaxRange = INITIAL_CIRCLE_RADIUS_RANGE_LIMIT;
    } else if (radiusRange.indexOf(r) === radiusRange.length - 1) {
      newRadiusMaxRange = Math.min(MAX_CIRCLE_RADIUS_RANGE_LIMIT, radiusMaxRange * 2);
    }

    // aggiorniamo i possibili valori dello slider, se necessario
    if (newRadiusMaxRange !== radiusMaxRange) {
      updateRadiusMaxRange(newRadiusMaxRange);
      updateRadiusRange(sliderValues(MIN_CIRCLE_RADIUS, newRadiusMaxRange, 10));
    }
    fetchAR({
      radius: radiusRef.current,
      center: circleRef.current.getCenter(),
    });
  };

  /** CREAZIONE DEL CERCHIO */

  const createCircle = ({
    initial = false,
    center,
    doSetCenter = true,
    fromSSearch = false,
    fromBack = false,
    radius = null,
  }) => {
    const {
      core: { LatLng },
      maps: { Polyline, Circle },
    } = libraries;
    
    const radiusToUse = fromSSearch ? radius : r;

    if (fromSSearch) {
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;

        circleRef.current.setMap(null);
        circleRef.current = null;

        deleteMarkerRef.current.setMap(null);
        deleteMarkerRef.current = null;
        editMarkerRef.current.setMap(null);
        editMarkerRef.current = null;        
        centerMarkerIcon.current.setMap(null);
        centerMarkerIcon.current = null;
      }

      setSaveSearchDrawingData(null);
      createdFromSSearch.current = false;

      if (radiusToUse !== r) {
        updateRadius(radiusToUse);

        let newRadiusMaxRange = radiusMaxRange;
        if (radiusToUse < INITIAL_CIRCLE_RADIUS_RANGE_LIMIT && radiusMaxRange > INITIAL_CIRCLE_RADIUS_RANGE_LIMIT) {
          newRadiusMaxRange = INITIAL_CIRCLE_RADIUS_RANGE_LIMIT;
        } else if (radiusRange.indexOf(radiusToUse) === radiusRange.length - 1) {
          newRadiusMaxRange = Math.min(MAX_CIRCLE_RADIUS_RANGE_LIMIT, radiusMaxRange * 2);
        }
    
        // aggiorniamo i possibili valori dello slider, se necessario
        if (newRadiusMaxRange !== radiusMaxRange) {
          updateRadiusMaxRange(newRadiusMaxRange);
          updateRadiusRange(sliderValues(MIN_CIRCLE_RADIUS, newRadiusMaxRange, 10));
        }
      }
    }
 
    // creiamo la polyline con forma circolare
    polylineRef.current = new Polyline(initial ? polylineStyle.complete : polylineStyle.editing);
    polylineRef.current.setPath(drawCircle(center, radiusToUse / 1609.344, 1, LatLng, 'createCircle'));
    polylineRef.current.setMap(map);

    // creiamo il cerchio
    circleRef.current = new Circle(initial ? polygonStyle.complete : polygonStyle.drawing);
    circleRef.current.setOptions({ center, radius: radiusToUse });
    circleRef.current.setMap(map);

    if (doSetCenter) {
      setCenter(center);
    }

    // calcoliamo le coordinate dei punti cardinali sul perimetro del cerchio
    const cpc = getCardinalPointsCoordinates();

    createDeleteMarker(cpc.np);
    createCenterMarker({ circleCenter: center });

    if (initial) {
      createEditMarker({
        cardinalPointsCoordinates: cpc,
        circleCenter: center,
        sSearchRadius: radiusToUse,
      });

      const activeShapes = [
        polylineRef.current,
        circleRef.current,
        deleteMarkerRef.current,
        centerMarker.current,
        centerMarkerIcon.current,
        editMarkerRef.current,
      ];

      const newDrawingData = {
        ...drawingData,
        fromSSearch: false,
        activeShapesToRemove: null,
        activeShapes,
        shapeBounds: circleRef.current.getBounds(),
        from: DRAWING_ACTIONS_MAP.circle,
      }

      setDrawingData(newDrawingData);

      if (fromBack) {
        synchPreviousStepsActiveShape(activeShapes);
        const cCenter = circleRef.current.getCenter();
        setGeoFilters({
          newfilter: {
            geocircle: {
              circle: [
                {
                  distance: radiusRef.current,
                  center: [cCenter.lat(), cCenter.lng()],
                },
              ],
            },
            q: null,
            geobounds: null,
            geopolygon: null,
            nearby: null,
            ...filtersToAddToQuery.current,
          },
          resetLocations: true,
          // searchAddress,
        });
        // setBoundsChangedOnBack(true);
        map.fitBounds(circleRef.current.getBounds());
      } else {
        setInitialShapeForPrevious(newDrawingData);
      }
    } else {
    // su desktop creiamo i marker draggabili sui punti cardinali del cerchio
      if (deviceRef.current === 'desktop') {
        setVertexes(cpc);
      }
      createCenterMsgMarker({ circleCenter: center, animated: true });
      setHasDrawing(true);
      setIsDrawing(false);
      fetchAR({
        radius: r,
        center,
      });      
    }
    // riposizioniamo la mappa, se necessario
    if (initial && drawingData?.from === DRAWING_ACTIONS_MAP.circle) {
      return;
    }

    mapBoundsOnCreateShape.current = map.getBounds();
    map.fitBounds(circleRef.current.getBounds());
  };

  // eventi legati al click sulla mappa quando si entra in modalità disegno
  const touchStartListener = (e) => {
    // se l'utente fa uno swipe a un dito
    if (e.touches.length === 1) {
      // abilitiamo il disegno
      hasClickedRef.current = true;
      // salviamo le coordinate a cui è avvenuto il click
      clickCoordsRef.current = { clientX: e.touches[0].clientX, clientY: e.touches[0].clientY };
    }
    // altrimenti sappiamo che sta zoomando o facendo pan sulla mappa
  };
  
  const handleMouseDown = useCallback((e) => {
    // per i dispositivi touch usiamo un metodo debounced per metterci in ascolto delle two fingers gestures
    if (e.touches && !!e.touches[0]) {    
      touchStartListener(e);
      return;
    }
    // abilitiamo il disegno
    hasClickedRef.current = true;
    // salviamo le coordinate a cui è avvenuto il click
    clickCoordsRef.current = { clientX: e.clientX, clientY: e.clientY };
  }, [map]);

  const handleMouseMove = useCallback((e) => {
    // solo se l'utente muove il puntatore o il dito senza aver rilasciato il click
    if (hasClickedRef.current) {
      // registriamo che l'utente sta muovendo il puntatore
      hasDraggedRef.current = true;
      // consideriamo l'evento corretto a seconda del device
      const correctEvt = e.touches && !!e.touches[0]
        ? e.touches[0]
        : e;
      // salviamo l'ampienza della gesture che sta compiendo
      moveCoordsRef.current = { x: Math.abs(correctEvt.clientX - clickCoordsRef.current.clientX), y: Math.abs(correctEvt.clientY - clickCoordsRef.current.clientY) };
    }
  }, [map]);

  const handleMouseUp = useCallback(() => {
    // se l'utente non ha mosso il puntatore o se lo ha mosso di <= 10px in qualsiasi direzione
    // consideriamo l'evento come un click e partiamo col disegno
    if (!hasDraggedRef.current || (moveCoordsRef.current.x <= 10 && moveCoordsRef.current.y <= 10)) {
      // ricaviamo l'oggetto latLng dal punto in cui è avvenuto il click
      const clickedLatLngPoint = mouseCoordsToLatLng(clickCoordsRef.current, map, device, isSafari);
      createCircle({ center: clickedLatLngPoint });
    }

    hasClickedRef.current = false;
    hasDraggedRef.current = false;
    clickCoordsRef.current = null;
    moveCoordsRef.current = { x: 0, y: 0 };
  }, [map, device, isSafari]);

  useEffect(() => {
    // quando l'utente inizia a disegnare
    if (isDrawing) {
      if (deviceRef.current !== 'desktop') {
        container.current.addEventListener('touchstart', handleMouseDown);
        container.current.addEventListener('touchmove', handleMouseMove);
        container.current.addEventListener('touchend', handleMouseUp);
      } else {
        map && map.setOptions({ draggableCursor: 'crosshair' });

        container.current.addEventListener('mousedown', handleMouseDown);
        container.current.addEventListener('mousemove', handleMouseMove);
        container.current.addEventListener('mouseup', handleMouseUp);
      }
    // una volta che l'utente ha completato il disegno del cerchio
    } else if (deviceRef.current !== 'desktop') {
      if (container.current) {
        container.current.removeEventListener('touchstart', handleMouseDown);
        container.current.removeEventListener('touchmove', handleMouseMove);
        container.current.removeEventListener('touchend', handleMouseUp);
      }
    } else {
      map && map.setOptions({ draggableCursor: null });
      if (container.current) {
        container.current.removeEventListener('mousedown', handleMouseDown);
        container.current.removeEventListener('mousemove', handleMouseMove);
        container.current.removeEventListener('mouseup', handleMouseUp);
      }
    }

    return () => {
      if (container.current) {
        if (deviceRef.current !== 'desktop') {
          if (container.current) {
            container.current.removeEventListener('touchstart', handleMouseDown);
            container.current.removeEventListener('touchmove', handleMouseMove);
            container.current.removeEventListener('touchend', handleMouseUp);
          }
        } else {
          map && map.setOptions({ draggableCursor: null });

          if (container.current) {
            container.current.removeEventListener('mousedown', handleMouseDown);
            container.current.removeEventListener('mousemove', handleMouseMove);
            container.current.removeEventListener('mouseup', handleMouseUp);
          }
        }
      }
    };
  }, [
    libraries,
    map,
    container,
    isDrawing,
    device,
  ]);

  // creo il cerchio a partire dai parametri della url
  useEffect(() => {
    if (initialized && centerFromInit) {
      const { core: { LatLng } } = libraries;
      createCircle({
        initial: true,
        center: new LatLng({ lat: centerFromInit[0], lng: centerFromInit[1] }),
        fromBack: drawingData?.fromBack,
      });
    }
  }, [initialized, centerFromInit]);

  // creo il cerchio a partire dalla posizione (landing: search around you)
  useEffect(() => {
    if (initialized && circleFromPos?.center) {
      const { core: { LatLng } } = libraries;
      createCircle({
        center: new LatLng({ lat: circleFromPos?.center[0], lng: circleFromPos?.center[1] }),
      });
    }
  }, [circleFromPos]);

  useEffect(() => {    
    if (drawingData && !Object.keys(circleFromPos || {}).length) {
      if (drawingData.action !== DRAWING_ACTIONS_MAP.circle) {        
        if ((drawingData.activeShapes || []).length) {
          removeActiveShapes({ drawingData });
        }
      } else if ((drawingData.activeShapesToRemove || []).length && !createdFromSSearch.current) {
        createdFromSSearch.current = true;        
        const fromSSearch = true;
        removeActiveShapes({ drawingData, fromSSearch });
        createCircle({
          initial: true,
          center: new LatLng({ lat: drawingData?.circle?.center[0], lng: drawingData?.circle?.center[1] }),
          radius: drawingData?.circle?.radius,
          fromSSearch,
        });
      } else if (drawingData.availableResults.value !== availableResults.value) {
        updateAvailableResults({ ...availableResults, value: drawingData.availableResults.value });
      }
    }
  }, [drawingData, circleFromPos]);

  // aggiorno la referenza interna di searchMode e se necessario passo alla modalità editing
  useEffect(() => {
    searchModeRef.current = searchMode;
    if (searchMode === DRAWING_ACTIONS_MAP.circle && circleRef.current && !startEditingRef.current) {
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;
      }
      if (circleRef.current) {
        circleRef.current.setMap(null);
        circleRef.current = null;
      }

      // cancelliamo l'editMarker
      if (editMarkerRef.current) {
        editMarkerRef.current.setMap(null);
        editMarkerRef.current = null;
      }

      // cancelliamo il deleteMarkerRef
      if (deleteMarkerRef.current) {
        deleteMarkerRef.current.setMap(null);
        deleteMarkerRef.current = null;
      }

      if (centerMarkerIcon.current) {
        centerMarkerIcon.current.setMap(null);
        centerMarkerIcon.current = null;
      }

      setIsDrawing(true);
      setHasDrawing(false);
      setCenter({ delete: true });
    } else if (startEditingRef.current) {
      startEditingRef.current = false;
    }
  }, [searchMode]);

  // aggiorno se dalla pagina cerca intorno a te cambio channel
  useEffect(() => {
    if (initialized && channel) {
      chn.current = channel;
      fetchAR({
        radius: r,
        center: circleRef.current.getCenter(),
      });
    }
  }, [channel, circleRef]);
  
  useEffect(() => {
    deviceRef.current = device;
  }, [device]);

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    if (deleteDraftAndStopSearchMode) {
      if (drawingData && drawingData.action === DRAWING_ACTIONS_MAP.circle) {
        revertCircle();
      } else {
        deleteCircle();
      }
      setSearchMode(null);
      toggleDeleteDraftAndStopSearchMode();
    }
  }, [deleteDraftAndStopSearchMode]);

  return initialized && (
    <Overlayer
      isOpen={!!searchMode}
      columnsAreCollapsed={columnsAreCollapsed}
    >
      <Controls
        map={map}
        searchMode={searchMode}
        setSearchMode={setSearchMode}
        device={device}
        mobileOs={mobileOs}
        libraries={libraries}
        center={c}
        createCircle={createCircle}
        deleteCircle={deleteCircle}
        radius={r}
        radiusRange={radiusRange}
        updateCircleRadius={updateCircleRadius}
        afterUpdateCircleRadius={afterUpdateCircleRadius}
        availableResults={availableResults}
        confirmCircle={confirmCircle}
        drawingData={drawingData}
        hasDrawing={hasDrawing}
        revertCircle={revertCircle}
        updateFiltersToAddToMapQuery={updateFiltersToAddToMapQuery}
        setDrawingDataAddress={setDrawingDataAddress}
        viewportWidth={viewport?.width || 0}
        isSearchAroundYou={isSearchAroundYou}
        openQbFiltersPanel={openQbFiltersPanel}
        qbGoToSRP={qbGoToSRP}
        searchArounProps={{
          ...searchArounProps,
          hasCircleRef: !!circleRef.current,
        }}
        qbSSearchAction={qbSSearchAction}
        controlsVariant={controlsVariant}
        availablePoi={availablePoi}
        isShowSaveAddressDialogOpen={isShowSaveAddressDialogOpen}
        showSaveAddressDialog={showSaveAddressDialog}
        isHandleAddressesModalOpen={isHandleAddressesModalOpen}
        showHandleAddressesModal={showHandleAddressesModal}
        updateSearchAddressRef={updateSearchAddressRef}
      />
    </Overlayer>
  );
};

export default CircleTool;

CircleTool.propTypes = {
  initialized: PropTypes.bool,
  map: PropTypes.instanceOf(Object),
  container: PropTypes.instanceOf(Object),
  searchMode: PropTypes.string,
  setSearchMode: PropTypes.func,
  device: PropTypes.string,
  mobileOs: PropTypes.string,
  libraries: PropTypes.instanceOf(Object),
  setPinpoint: PropTypes.func,
  drawingData: PropTypes.instanceOf(Object),
  updateQuery: PropTypes.func,
  setDrawingData: PropTypes.func,
  setGeoFilters: PropTypes.func,
  getAvailableResults: PropTypes.func,
  columnsAreCollapsed: PropTypes.bool,
  viewport: PropTypes.instanceOf(Object),
  isSearchAroundYou: PropTypes.bool,
  circleFromPos: PropTypes.instanceOf(Object),
  openQbFiltersPanel: PropTypes.func,
  qbGoToSRP: PropTypes.func,
  searchArounProps: PropTypes.instanceOf(Object),
  qbSSearchAction: PropTypes.func,
  controlsVariant: PropTypes.string,
  setSaveSearchDrawingData: PropTypes.func,
  isSafari: PropTypes.bool,
  showYouGotAwayMsg: PropTypes.bool,
  setShowYouGotAwayMsg: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
};

CircleTool.defaultProps = {
  initialized: false,
  map: null,
  container: null,
  searchMode: null,
  setSearchMode: null,
  device: '',
  mobileOs: '',
  libraries: null,
  setPinpoint: () => { },
  drawingData: null,
  updateQuery: () => { },
  setDrawingData: () => { },
  setGeoFilters: () => { },
  getAvailableResults: () => { },
  columnsAreCollapsed: false,
  viewport: {},
  isSearchAroundYou: false,
  circleFromPos: {},
  openQbFiltersPanel: null,
  qbGoToSRP: null,
  searchArounProps: {},
  qbSSearchAction: null,
  controlsVariant: null,
  setSaveSearchDrawingData: () => {},
  isSafari: false,
  showYouGotAwayMsg: false,
  setShowYouGotAwayMsg: () => {},
  filters: {},
};
