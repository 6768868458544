import React from 'react';

import SeoManager from 'SeoManager';

import onlyFirstLetterUppercase from 'shared-utils/src/onlyFirstLetterUppercase';
import { isValidVar, isArray, isValidString } from 'shared-utils/src/checkVarType';

import SEOPagesPtypes from 'shared-constants/src/seoPagesData';

import { capitalizeFirstLetter } from './STRINGHelpers';

export const SEOFooterRentHPDesc = 'Casa.it ti aiuta a fare la scelta migliore tra migliaia di annunci di case in affitto in tutta Italia, fornendoti le informazioni di cui hai bisogno: foto, planimetrie, servizi disponibili e tour virtuali. Se sei alla ricerca di una casa in affitto, Casa.it ti aiuta a trovarla in modo facile e veloce, grazie alle schede consultabili direttamente dal tuo smartphone o dal tuo pc. L’ampia offerta di proposte è corredata da tutti i dettagli di cui hai bisogno per fare la scelta migliore per te e la tua famiglia. Scegli tu dove! A due passi dal centro, dalla stazione o dal luogo di lavoro. Valuta l’offerta che più si addice alle tue esigenze e trova la tua futura casa in affitto.';

const additionalNonSEOPTypeGroups = {
  cantine: {
    plural: 'Cantine e solai',
    forurl: 'cantine-solai',
    every: 'tutte le cantine e i solai',
    publisherHpSlug: 'cantine-e-solai',
    publisherHpText: 'cantine e solai',
  },
  'cantine-solai': {
    plural: 'Cantine e solai',
    forurl: 'cantine-solai',
    every: 'tutte le cantine e i solai',
    publisherHpSlug: 'cantine-e-solai',
    publisherHpText: 'cantine e solai',
  },
  'case-vacanza': {
    plural: 'Case vacanza',
    forurl: 'case-vacanza',
    every: 'tutte le case vacanza',
    publisherHpSlug: 'case-vacanza',
    publisherHpText: 'case vacanza',
  },
};

const SEOPagesNumroomsCommon = {
  seofooterTitle: {
    vendita: {
      tag: 'h1',
    },
    affitto: {
      tag: 'h1',
    },
  },
  seofooterDesc: {
    vendita: {
      tag: 'p',
      txt: SEOPagesPtypes.appartamento.seofooterDesc.vendita.txt,
    },
    affitto: {
      tag: 'p',
      txt: SEOPagesPtypes.appartamento.seofooterDesc.affitto.txt,
    },
  },
};

export const SEOPagesNumrooms = {
  1: {
    plural: 'Monolocali',
    singular: 'Monolocale',
    pluralWA: 'I monolocali',
    singularWA: 'Il monolocale',
    every: 'Tutti i monolocali',
    forurl: 'monolocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  2: {
    plural: 'Bilocali',
    singular: 'Bilocale',
    pluralWA: 'I bilocali',
    singularWA: 'Il bilocale',
    every: 'Tutti i bilocali',
    forurl: 'bilocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  3: {
    plural: 'Trilocali',
    singular: 'Trilocale',
    pluralWA: 'I trilocali',
    singularWA: 'Il trilocale',
    every: 'Tutti i trilocali',
    forurl: 'trilocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  4: {
    plural: 'Quadrilocali',
    singular: 'Quadrilocale',
    pluralWA: 'I quadrilocali',
    singularWA: 'Il quadrilocale',
    every: 'Tutti i quadrilocali',
    forurl: 'quadrilocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  monolocale: {
    plural: 'Monolocali',
    singular: 'Monolocale',
    pluralWA: 'I monolocali',
    singularWA: 'Il monolocale',
    every: 'Tutti i monolocali',
    forurl: 'monolocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  bilocale: {
    plural: 'Bilocali',
    singular: 'Bilocale',
    pluralWA: 'I bilocali',
    singularWA: 'Il bilocale',
    every: 'Tutti i bilocali',
    forurl: 'bilocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  trilocale: {
    plural: 'Trilocali',
    singular: 'Trilocale',
    pluralWA: 'I trilocali',
    singularWA: 'Il trilocale',
    every: 'Tutti i trilocali',
    forurl: 'trilocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },
  quadrilocale: {
    plural: 'Quadrilocali',
    singular: 'Quadrilocale',
    pluralWA: 'I quadrilocali',
    singularWA: 'Il quadrilocale',
    every: 'Tutti i quadrilocali',
    forurl: 'quadrilocali',
    seofooterTitle: {
      ...SEOPagesNumroomsCommon.seofooterTitle,
    },
    seofooterDesc: {
      ...SEOPagesNumroomsCommon.seofooterDesc,
    },
  },

};

export const SEOPagesAuction = {
  plural: 'Aste Giudiziarie Immobiliari',
  singular: 'Asta Giudiziaria Immobiliare',
  pluralWA: 'Aste Giudiziarie Immobiliari',
  singularWA: 'Asta Giudiziaria Immobiliare',
  every: 'Tutte le aste giudiziarie immobiliari',
  forurl: 'aste',
  seofooterTitle: {
    aste: {
      tag: 'h1',
    },
  },
  seofooterDesc: {
    aste: {
      tag: 'h2',
      txt: 'Le aste giudiziarie spesso rappresentano un’ottima opportunità per acquistare un immobile a basso costo. Su Casa.it sono disponibili migliaia di annunci corredati da foto e informazioni utili per scegliere l’immobile ideale. Se sei alla ricerca di un’asta immobiliare valuta attraverso i nostri filtri, scegliendo il numero di locali, il prezzo massimo e la grandezza dell’immobile. Vuoi una casa all’asta con giardino o con ascensore? Vorresti trovare le migliori occasioni provenienti da fallimenti? Appartamento o Villa? Cerca su Casa.it attraverso la nostra applicazione o direttamente sul sito Web. Guarda tra i risultati, gli immobili all’asta sono segnalati con l’etichetta ASTA',
    },
  },
};

export const SEOTitleEvery = (property, bc, isAgencyHome, onlyAuction) => {
  if (isAgencyHome) {
    return 'tutte le agenzie';
  }
  if (onlyAuction) {
    return 'tutti gli immobili all\'asta';
  }
  const match = property === 'residenziale' && bc === 'nuovo'
    ? 'nuove-costruzioni'
    : property;
  if (SEOPagesPtypes[match] && SEOPagesPtypes[match].every) {
    return SEOPagesPtypes[match].every.toLowerCase();
  }
  return 'tutti gli immobili';
};

export const SEODataByKey = (property, match, dataType = null) => {
  let data = SEOPagesPtypes;
  if (dataType && dataType === 'rooms') {
    data = SEOPagesNumrooms;
  }
  if (dataType === 'auction') {
    return [SEOPagesAuction];
  }
  if (dataType && dataType === 'publisher') {
    data = Object.assign({}, SEOPagesPtypes, additionalNonSEOPTypeGroups);
  }
  return Object.keys(data).map(
    (ptype) => {
      if (data[ptype][property] === match) {
        return data[ptype];
      }
    },
  ).filter(Boolean);
};

export const SEOPageTitlePtype = (ptype, numRooms, withArticle = false) => {
  if (
    isValidVar(numRooms)
    && numRooms > 0
  ) {
    return withArticle ? SEOPagesNumrooms[numRooms].pluralWA : SEOPagesNumrooms[numRooms].plural;
  }
  return withArticle ? SEOPagesPtypes[ptype]?.pluralWA : SEOPagesPtypes[ptype]?.plural;
};

export const SEOPageLinkPType = ({
  ptype = '',
  numRooms = '',
  ltGroup = '',
  shortRent = false,
}) => {
  /**
   * ptype !== 'vacanze' -> we use the ptype to generally identify name of tha tab
   * but in this case is not necessary because we have 'shortRent' that simplify the logic 
   */
  if (ltGroup) {
    const plural = SeoManager.getPluralLTgGroup(ltGroup);
    if (plural) {
      return SeoManager.getPluralLTgGroup(ltGroup);
    }
  }
  if (ptype !== '' && ptype !== 'vacanze') {
    if (numRooms) {
      if (shortRent) {
        return `${SEOPagesNumrooms[numRooms].plural} in affitto breve`;
      }
      return SEOPagesNumrooms[numRooms].plural;
    }
    if (shortRent) {
      return `${SEOPagesPtypes[ptype]?.plural} in affitto breve`;
    }
    return SEOPagesPtypes[ptype]?.plural;
  }
  if (shortRent) {
    return 'Case in affitto breve';
  }
  return 'Case';
};

export const SEOPageTabLink = (category, propertyType, numRooms, buildingCondition, seofooter = true) => {
  if (
    category === 'residenziale'
    && isValidVar(propertyType) && !isValidString(propertyType)
    && buildingCondition === null
  ) {
    return seofooter ? '/#seoFooter' : '/residenziale/';
  }
  let ptypeToUse = propertyType !== '' ? propertyType : category;
  if (buildingCondition) {
    ptypeToUse = 'nuove-costruzioni';
  }
  return `${numRooms ? '/residenziale' : ''}${!numRooms ? `/${SEOPagesPtypes[ptypeToUse].forurl}` : ''}${numRooms ? `/${SEOPagesNumrooms[numRooms]?.forurl}` : ''}/${seofooter ? '#seoFooter' : ''}`;
};

const SEOPageMenuAllowedValues = (values, valuesType) => {
  let allowedValues = [];  
  if (
    values.length === 0
    || (
      values[0].generic.length === 1
      && values[0].generic[0].label === 'Tutti'
    )
  ) {
    return allowedValues;
  }
  if (valuesType === 'ptypes') {
    allowedValues = values[0].generic.filter(value => value.slugs === '' || 'seomenuitem' in SEOPagesPtypes[value.slugs]);
  } else {    
    allowedValues = values[0].generic;
  }
  return allowedValues;
};

export const SEOPageFormatMenus = (
  values,
  currentValue,
  pageType,
  buildingCondition = null,
) => {
  const allowedValues = SEOPageMenuAllowedValues(values, pageType);
  if (allowedValues.length === 0) {
    return null;
  }
  let value = currentValue;
  if (buildingCondition) {
    value = 'nuove-costruzioni';
  }

  const menuItems = allowedValues.map(
    type => (
      <li key={type.value} className={value === type.value ? 'active' : ''}>
        <a
          id={type.anchor || 'seoFooter'}
          href={type.href}
          title={type.name}
        >
          {onlyFirstLetterUppercase(type.name)}
        </a>
      </li>
    ),
  );
  return menuItems;
};

export const SEOPageFormatSelects = (
  values,
  currentValue,
  onButtonClick,
  onSelectChange,
  valuesType,
  onlyAuction = false,
) => {
  const allowedValues = valuesType === 'licenseTypeGroups' ? values : SEOPageMenuAllowedValues(values, valuesType);
  if (allowedValues.length === 0 || onlyAuction) {
    return null;
  }

  const value = currentValue;

  const options = allowedValues.map(
    type => (
      <li
        key={type.value}
        className={type.value === value ? 'active' : ''}
      >
        <button
          type="button"
          id={type.href}
          href={type.href || '/'}
          onClick={(e) => { onSelectChange({ e, data: type, values: allowedValues }); }}
        >
          {onlyFirstLetterUppercase(type.name)}
        </button>
      </li>
    ),
  );
  let selectCssClassName = typeof value === 'undefined' || value === '' || value === 0 || value === null ? `htmlSelect ${valuesType}Select noselection` : `htmlSelect ${valuesType}Select`;
  let selectDisplayValue;
  if (typeof value === 'undefined' || value === '' || value === 0 || value === null) {
    selectDisplayValue = `Scegli: ${allowedValues.map(innerType => onlyFirstLetterUppercase(innerType.name)).join(', ')}`;
  } else {
    try {
      selectDisplayValue = onlyFirstLetterUppercase(allowedValues.filter(el => el.value === value)[0].name);
    } catch (error) {
      selectCssClassName = `htmlSelect ${valuesType}Select noselection`;
      selectDisplayValue = `Scegli: ${allowedValues.map(innerType => onlyFirstLetterUppercase(innerType.name)).join(', ')}`;
    }
  }

  const select = (
    <div className={selectCssClassName}>
      <button
        type="button"
        aria-label={valuesType === 'ptypes' ? 'Scegli la tipologia di immobile' : 'Scegli il numero di stanze'}
        onClick={(e) => { onButtonClick(e); }}
      >
        {selectDisplayValue}
      </button>
      <ul className={valuesType === 'licenseTypeGroups' ? 'ltg' : ''}>
        {options}
      </ul>
    </div>
  );
  return select;
};

export const SEOTabsForPTypesAndNumRooms = ({
  groupMenuValue = [],
  pageType,
  currentValue,
  switchTab,
  onlyAuction,
}) => {
  const allowedValues = pageType === 'licenseTypeGroups' ? groupMenuValue : SEOPageMenuAllowedValues(groupMenuValue, pageType);
  if (allowedValues.length === 0 || onlyAuction) {
    return null;
  }
  const value = currentValue;
  const menuItems = allowedValues.map(
    type => (
      <li key={type.value} className={value === type.value ? 'active' : ''}>
        <button
          type="button"
          id={type.anchor}
          onClick={() => switchTab({
            value: type.value || 'all',
            context: type.group,
          })}
          title={type.name}
        >
          {onlyFirstLetterUppercase(type.name)}
        </button>
      </li>
    ),
  );
  return menuItems;
};


const SEORootPageFilter = (filters) => {
  const validSeoPagesFilters = [
    'tr',
    'category',
    'propertyTypes',
    'buildingCondition',
  ];
  const toReturn = {};
  Object.keys(filters).forEach(
    (filter) => {
      if (validSeoPagesFilters.indexOf(filter) > -1) {
        if (filter === 'category') {
          toReturn.propertyTypeGroup = filters[filter];
        }
        toReturn[filter] = filters[filter];
      }
    },
  );
  return toReturn;
};

export const SEOBreadcrumbHomeLink = (propsFilters, seoManager, isSrpUri, isAgencySrp) => {

  const filters = propsFilters;
  const filtersSeoRootPage = SEORootPageFilter(filters);

  // console.log('')
  // console.log('BREADCRUMB HOMELINK');
  // console.log('')
  // console.log(filters);
  // console.log(filtersSeoRootPage);

  const channel = filters.tr === 'affitti' ? 'affitto' : filters.tr;
  if (isValidVar(filters.propertyTypes)) {
    filters.propertyTypes = isArray(filters.propertyTypes) ? filters.propertyTypes.join(', ') : filters.propertyTypes;
  }

  let href = '/';
  let title = `Case e appartamenti in ${channel} – Annunci immobiliari - Casa.it`;
  let event = 'Homepage';
  let rel = '';

  try {
    if (isAgencySrp) {
      title = 'Agenzie Immobiliari, cerca l\'agenzia più vicina a te - Casa.it';
      event = 'Homepage agenzie';
      href = '/agenzie/#seoFooter';
    } else if ( // NUMROOMS
      'numRoomsMin' in filters
      && 'numRoomsMax' in filters
      && filters.numRoomsMin === filters.numRoomsMax
      && Object.keys(SEOPagesNumrooms).indexOf(filters.numRoomsMin.toString()) !== -1
    ) {
      // VALIDO
      const numRooms = filters.numRoomsMin.toString();
      if (isSrpUri) {
        filters.propertyTypeGroup = filters.category;
        filters.forceSrp = true;
        href = seoManager.encode(filters);
      } else {
        filtersSeoRootPage.numRoomsMin = filters.numRoomsMin;
        filtersSeoRootPage.numRoomsMax = filters.numRoomsMax;
        href = `/liste${seoManager.encode(filtersSeoRootPage)}#seoFooter`;
      }

      // console.log('numRooms:', numRooms);
      // console.log('valida per il seo:', Object.keys(SEOPagesNumrooms).indexOf(numRooms) !== -1);

      title = `${SEOPagesNumrooms[numRooms].plural} in ${channel} – Annunci immobiliari - Casa.it`;
      event = `Homepage ${SEOPagesNumrooms[numRooms].plural.toLowerCase()} ${channel}`;
      // console.log('link al seofooter');
      // console.log('url: ', href);
      // console.log('title: ', title);
      // console.log('event: ', event);

      // CATEGORY
    } else if (!('propertyTypes' in filters)) {
      // console.log('nessuna property type');
      // console.log('category:', filters.category);
      // console.log('valida per il seo:', Object.keys(SEOPagesPtypes).indexOf(filters.category) !== -1);
      // console.log('isSrpUri', isSrpUri);
      // NON  VALIDO
      if (Object.keys(SEOPagesPtypes).indexOf(filters.category) < 0 || isSrpUri) {
        filters.propertyTypeGroup = filters.category;
        const propertyTypeGroup = isValidString(filters.category) ? filters.category.replace(/-/g, ' ') : '';
        filters.forceSrp = true;
        href = `${seoManager.encode(filters)}`;
        title = `${capitalizeFirstLetter(propertyTypeGroup)} in ${channel} – Annunci immobiliari - Casa.it`;
        event = `Homepage ${propertyTypeGroup} ${channel}`;
        // console.log('togliere la location');
        // console.log('url: ', href);
        // console.log('title: ', title);
        // console.log('event: ', event);
        // VALIDO
      } else if (
        filtersSeoRootPage.category === 'case'
        && filtersSeoRootPage.propertyTypeGroup === 'case'
      ) {
        const projectprofile = 'buildingCondition' in filtersSeoRootPage && filtersSeoRootPage.buildingCondition === 'nuovo';
        if (projectprofile) {
          href = filtersSeoRootPage.tr === 'vendita'
            ? '/liste/vendita/residenziale/nuove-costruzioni/#seoFooter'
            : '/liste/affitto/residenziale/nuove-costruzioni/#seoFooter';
          title = `Nuove costruzioni in ${channel} – Annunci immobiliari - Casa.it`;
          event = `Homepage nuove costruzioni ${channel}`;
        } else {
          href = filtersSeoRootPage.tr === 'vendita'
            ? '/#seoFooter'
            : '/liste/affitto/#seoFooter';

          title = `${SEOPagesPtypes[filters.category].plural} in ${channel} – Annunci immobiliari - Casa.it`;
          event = `Homepage ${SEOPagesPtypes[filters.category].plural.toLowerCase()} ${channel}`;
        }
      } else {
        href = `/liste${seoManager.encode(filtersSeoRootPage)}#seoFooter`;
        title = `${SEOPagesPtypes[filters.category].plural} in ${channel} – Annunci immobiliari - Casa.it`;
        event = `Homepage ${SEOPagesPtypes[filters.category].plural.toLowerCase()} ${channel}`;
      }
      // console.log('link al seofooter');
      // console.log('url: ', href);
      // console.log('title: ', title);
      // console.log('event: ', event);


      // PTYPES MULTIPLE
    } else if (filters.propertyTypes.split(',').length > 1) {
      // console.log('>1 property type');
      // NON VALIDO
      const humanPtype = filters.propertyTypes;
      filters.propertyTypes = filters.propertyTypes.replace(/ /g, '+');
      filters.propertyTypeGroup = filters.category;
      filters.forceSrp = true;
      href = `${seoManager.encode(filters)}`;
      title = `${capitalizeFirstLetter(humanPtype.split(',').join(' o '))} in ${channel} – Annunci immobiliari - Casa.it`;
      event = `Search ${humanPtype} ${channel}`;
      // console.log('togliere la location');
      // console.log('url: ', href);
      // console.log('title: ', title);
      // console.log('event: ', event);

      // SINGOLA PTYPE
    } else if (Object.keys(SEOPagesPtypes).indexOf(filters.propertyTypes) < 0 || isSrpUri) {
      // NON VALIDO
      // console.log('una property type');
      // console.log('property type:', filters.propertyTypes);
      // console.log('valida per il seo:', Object.keys(SEOPagesPtypes).indexOf(filters.propertyTypes) !== -1);
      const humanPtype = filters.propertyTypes.split(' ').join(', ');
      filters.propertyTypes = filters.propertyTypes.replace(/ /g, '+');
      filters.propertyTypeGroup = filters.category;
      filters.forceSrp = true;
      href = `${seoManager.encode(filters)}`;
      title = `${capitalizeFirstLetter(humanPtype)} in ${channel} – Annunci immobiliari - Casa.it`;
      event = `Search ${humanPtype} ${channel}`;
      // console.log('togliere la location');
      // console.log('url: ', href);
      // console.log('title: ', title);
      // console.log('event: ', event);
    } else {
      // VALIDO
      if (isSrpUri) {
        filters.forceSrp = true;
        href = seoManager.encode(filters);
      } else {
        // console.log('una property type');
        // console.log('property type:', filters.propertyTypes);
        // console.log('valida per il seo:', Object.keys(SEOPagesPtypes).indexOf(filters.propertyTypes) !== -1);
        href = `/liste${seoManager.encode(filtersSeoRootPage)}#seoFooter`;
      }
      title = `${SEOPagesPtypes[filters.propertyTypes].plural} in ${channel} – Annunci immobiliari - Casa.it`;
      event = `Homepage ${SEOPagesPtypes[filters.propertyTypes].plural.toLowerCase()} ${channel}`;
      // console.log('link al seofooter');
      // console.log('url: ', href);
      // console.log('title: ', title);
      // console.log('event: ', event);
    }
  } catch (error) {
    console.log('SEOBreadcrumbHomeLink - Error');
    console.log(error);
  }
  if (
    !isSrpUri
    && filters.category === 'case-vacanza'
  ) {
    href = '/#seoFooter';
    title = `Case in ${channel} – Annunci immobiliari - Casa.it`;
    event = `Homepage ${channel}`;
  }
  if (
    !isSrpUri
    && href.indexOf('/srp/?') >= 0
  ) {
    rel = 'nofollow';
  }

  // console.log('');
  // console.log('---');
  // console.log('');

  return {
    href,
    title,
    event,
    rel,
  };
};

export const chooseSeoFooterToUse = ({ context = 'default', key = 'all', seoFooterData = {} }) => {
  let ctxToUse = context;
  const keyToUse = key;
  if (context === 'ptypes' && key === 'all') {
    ctxToUse = 'default';
  }

  if (seoFooterData && seoFooterData[ctxToUse] && seoFooterData[ctxToUse][keyToUse]) {
    return seoFooterData[ctxToUse][keyToUse];
  }
  return [];
};

export const hasToShowNumRooms = ({ category, ptype }) => {
  if (
    (
      category === 'residenziale' && ptype === ''
    )
  ) {
    return true;
  }
  return false;
};


export const noresultsPType = (propertyType, nRooms, { ptype, ltg = '' }) => {
  let value = '';
  if (ltg) {
    value = `di ${ltg}`;
  } else if (nRooms) {
    let txt;
    switch (nRooms) {
      case 2:
        txt = 'bilocali';
        break;
      case 3:
        txt = 'trilocali';
        break;
      case 4:
        txt = 'quadrilocali';
        break;
      default:
        txt = 'monolocali';
    }
    value = `di ${txt}`;
  } else if (ptype) {
    value = `di ${SEOPagesPtypes[propertyType].plural.toLowerCase()}`;
  }
  return value;
};

const toggleZonesHeight = (el) => {
  const domElement = el;
  const domList = domElement.parentNode.firstChild;
  if (domList.classList.contains('expanded')) {
    domList.classList.remove('expanded');
    domElement.innerHTML = 'vedi tutte le zone';
  } else {
    domList.classList.add('expanded');
    domElement.classList.add('nomore');
    domElement.innerHTML = '';
  }
};

const createBaseHoverTextLocation = ({ onlyAuction, isAgencyHub, ptypeForLinks, zone, parent, loc }) => {
  let base = `${ptypeForLinks}`;
  if (isAgencyHub) {
    base = 'Agenzie immobiliari';
  } else if (onlyAuction) {
    base = 'Aste giudiziarie';
  }

  if (zone && parent) {
    return `${base} in zona ${zone.name.replace(/-/g, ' ')} a ${parent}`;
  }
  return `${base} a ${loc.name.replace(/-/g, ' ')}`;
};

const formatZonesList = (list, ptypeForLinks, parent, { isAgencyHub, onlyAuction }) => {
  const zones = list.map(
    zone => (
      <li key={`${zone.slug}_${Math.random()}`}>
        <a
          href={zone.href}
          title={createBaseHoverTextLocation({ onlyAuction, isAgencyHub, ptypeForLinks, zone, parent })}
          data-count={zone.count}
        >
          <em>{zone.name.replace(/-/g, ' ')}</em>
        </a>
      </li>
    ),
  );
  return (
    <div className="zonesCont">
      <ul className="zones">
        {zones}
      </ul>
      {
        zones.length > 3
        &&
        <button
          type="button"
          title={`Vedi tutte le zone di ${parent}`}
          className="expandZones"
          onClick={
            (e) => {
              e.preventDefault();
              toggleZonesHeight(e.target);
            }
          }
        >
          vedi tutte le zone
        </button>
      }
    </div>
  );
};

export const formatLocList = (list, ptypeForLinks, { isAgencyHub, onlyAuction }) => {
  const entries = [];

  list.forEach(
    (el) => {
      const initial = Object.keys(el)[0];
      const localities = el[initial];
      entries.push(<li key={`hubPage_${initial}_${Math.random()}`} className="initial"><span>{initial}</span></li>);
      localities.forEach(
        (loc) => {
          entries.push(
            <li key={`${loc.slugs}_${Math.random()}`}>
              <a
                href={loc.href}
                title={createBaseHoverTextLocation({ onlyAuction, isAgencyHub, ptypeForLinks, loc })}
                data-count={loc.count}
              >
                {loc.name.replace(/-/g, ' ')}
              </a>
              {
                'zones' in loc
                && loc.zones.length > 0
                &&
                formatZonesList(loc.zones, ptypeForLinks, loc.name, { isAgencyHub, onlyAuction })
              }
            </li>,
          );
        },
      );
    },
  );
  const itemsPerColumn = Math.floor(entries.length / 3);
  const diff = entries.length - (itemsPerColumn * 3);
  const col1 = [];
  const col2 = [];
  const col3 = [];
  let col1Limit;
  let col2Start;
  let col2Limit;
  let col3Start;
  let col3Limit;
  if (diff === 1) {
    col1Limit = itemsPerColumn + diff;
    col2Start = itemsPerColumn + diff;
    col2Limit = itemsPerColumn + diff + itemsPerColumn + diff;
    col3Start = itemsPerColumn + diff + itemsPerColumn + diff;
    col3Limit = itemsPerColumn + diff + itemsPerColumn + diff + itemsPerColumn;
  } else if (diff === 2) {
    col1Limit = (itemsPerColumn + (diff / 2));
    col2Start = (itemsPerColumn + (diff / 2));
    col2Limit = (itemsPerColumn + (diff / 2)) + itemsPerColumn + (diff / 2);
    col3Start = (itemsPerColumn + (diff / 2)) + itemsPerColumn + (diff / 2);
    col3Limit = ((itemsPerColumn + (diff / 2)) + itemsPerColumn + (diff / 2) + itemsPerColumn);
  } else {
    col1Limit = itemsPerColumn;
    col2Start = itemsPerColumn;
    col2Limit = (itemsPerColumn * 2);
    col3Start = itemsPerColumn * 2;
    col3Limit = (itemsPerColumn * 3);
  }

  let addPlaceholderToCol2 = true;
  let addPlaceholderToCol3 = true;
  entries.forEach(
    (place, index) => {
      if (index < col1Limit) {
        if (
          index === col1Limit - 1
          && place.key.indexOf('hubPage_') > -1
        ) {
          col2.push(place);
          addPlaceholderToCol2 = false;
        } else {
          col1.push(place);
        }
      } else if (
        index >= col2Start
        && index < col2Limit
      ) {
        if (
          index === col2Start
          && place.key.indexOf('hubPage_') === -1
          && addPlaceholderToCol2
        ) {
          col2.push(
            <li key={`hubPage_col2_placeholder_${Math.random()}`} className="initial"><span>&nbsp;</span></li>,
          );
        }
        if (
          index === col2Limit - 1
          && place.key.indexOf('hubPage_') > -1
        ) {
          col3.push(place);
          addPlaceholderToCol3 = false;
        } else {
          col2.push(place);
        }
      } else if (
        index >= col3Start
        && index < col3Limit
      ) {
        if (
          index === col3Start
          && place.key.indexOf('hubPage_') === -1
          && addPlaceholderToCol3
        ) {
          col3.push(
            <li key={`hubPage_col3_placeholder_${Math.random()}`} className="initial"><span>&nbsp;</span></li>,
          );
        }
        col3.push(place);
      }
    },
  );
  return (
    <div className="list">
      <div className="townsList">
        <ul>
          {col1}
        </ul>
      </div>
      <div className="townsList">
        <ul>
          {col2}
        </ul>
      </div>
      <div className="townsList">
        <ul>
          {col3}
        </ul>
      </div>
    </div>
  );
};

export const formatAllLocList = ({
  category,
  hubPage,
  isAgencyHub,
  onlyAuction,
}) => {
  const obj = {
    default: {},
    ptypes: {},
    nRooms: {},
    licenseTypeGroups: {},
  };
  Object.keys(hubPage).map((firstKey) => {
    Object.keys(hubPage[firstKey]).map((secondKey) => {
      if (firstKey !== 'generalInfo') {
        const val = hubPage[firstKey][secondKey];
        let ptypeForLinks = SEOPageLinkPType({
          ptype: category,
        });
        if (firstKey === 'ptypes') {
          ptypeForLinks = SEOPageLinkPType({
            ptype: secondKey,
            numRooms: '',
          });
        } else if (firstKey === 'nRooms') {
          ptypeForLinks = SEOPageLinkPType({
            ptype: true,
            numRooms: secondKey,
          });
        } else if (firstKey === 'licenseTypeGroups') {
          ptypeForLinks = SEOPageLinkPType({
            ltGroup: secondKey,
          });
        }
        obj[firstKey][secondKey] = formatLocList(val, ptypeForLinks, { isAgencyHub, onlyAuction });
      }

    });
  });
  return obj;
};

export const chooseSeoData = ({
  numrooms,
  goal = 'forurl',
  secondPos,
  onlyAuction,
}) => {
  if (numrooms && numrooms !== '') {
    return SEODataByKey(goal, numrooms, 'rooms')[0];
  }
  if (onlyAuction) {
    return SEODataByKey(goal, null, 'auction')[0];
  }
  return SEODataByKey(goal, secondPos)[0];
};

export const createMessageMainHrefHubpage = ({ isAgencyHub, numRooms, ptypeForText, locationName, onlyAuction, ltGroup }) => {

  let pt = SEOPageLinkPType({
    ptype: ptypeForText,
    numRooms,
    ltGroup,
  });
  if (isAgencyHub) {
    pt = 'Agenzie immobiliari';
  } else if (onlyAuction) {
    pt = 'Aste Giudiziarie';
  }
  const message = `Vedi ${pt} in provincia di ${locationName}`;
  return message;
};
