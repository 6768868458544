/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import Localization from 'shared-vectors/icons/MapLocalization';
import LocalizationIos from 'shared-vectors/icons/MapLocalizationIos';
import CloseCirc from 'shared-vectors/icons/CloseCirc';
import { Icon } from 'shared-vectors';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import Spinner from 'shared-components/components/Spinner';

import { setItem, getItem } from 'shared-utils/src/localStorage';
import debounce from 'shared-utils/src/debounce';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';
import LockScreen from 'shared-utils/src/lockScreen';

import { isChildOf } from 'client/helpers/DOMHelpers';

import { useCtxGlobal } from '@client/app/Container';

import { LS_RECENTADDRESSES, LS_FAVORITEADDRESSES } from '../../../constants';
import saveRecentAddress from '../../../helpers/saveRecentAddress';
import inputFocusOnBtnClick from '../../../helpers/inputFocusOnBtnClick';
import getAddress from '../../../helpers/getAddress';
import getUserPosition from '../../../helpers/getUserPosition';
import { getName, getDetails } from '../../../helpers/getAddressStrings';
import useSaveAddress from '../../../helpers/useSaveAddress';

import Pill from '../../layout/Pill';

import Suggestions from './suggestions';
import List from './list';

import './style.scss';

const GoogleSearch = ({
  map,
  mobileOs,
  device,
  places,
  core,
  geocoding,
  recentAddresses,
  className,
  searchTrigger,
  isSearching,
  setIsSearching,
  action,
  geolocationTrackPosition,
  toolControls,
  isQBuilder,
}) => {
  const {
    globalSelector, 
    globalReducer,
    handleAddressesModalData,
    showHandleAddressesModal,
    saveAddressDialogData,
    user,
    orientation,
  } = useCtxGlobal();

  const searchRef = useRef(null);
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const editRecentsRef = useRef(null);
  const editFavoritesRef = useRef(null);
  const autocompleteSuggestionRef = useRef(null);
  const placeRef = useRef(null);
  const sessionTokenRef = useRef(null);
  const lockScreenRef = useRef(new LockScreen('locked'));

  const saveAddressDialogDataRef = useRef(null);
  const isHandleAddressesModalOpenRef = useRef(null);
  
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState(null);
  const [recent, setRecent] = useState(getItem(LS_RECENTADDRESSES) || []);
  const [favorites, setFavorites] = useState(globalSelector('favoriteAddresses') || []);
  const [gsError, setGsError] = useState('');
  const [gsLoading, setGsLoading] = useState(false);
  const [isInpInFocus, setIsInpInFocus] = useState(false);

  const handleScrollEvent = useCallback(() => {
    inputRef?.current.blur();
  });

  const getRecents = () => JSON.parse(JSON.stringify(recent));
  const getFavorites = () => JSON.parse(JSON.stringify(favorites));

  const addScrollListener = (tgt) => {
    const ev = device === 'desktop' ? 'scroll' : 'touchstart';
    tgt?.addEventListener(ev, handleScrollEvent);
  };
  const removeScrollListener = (tgt) => {
    const ev = device === 'desktop' ? 'scroll' : 'touchstart';
    tgt?.removeEventListener(ev, handleScrollEvent);
  };

  const handleListeners = useRef({
    addScrollListener: (tgt) => addScrollListener(tgt),
    removeScrollListener: (tgt) => removeScrollListener(tgt),
  });

  useEffect(() => {
    const internalRef = scrollRef?.current;
    handleListeners.current.addScrollListener(internalRef);
    return () => handleListeners.current.removeScrollListener(internalRef);
  }, []);

  useEffect(() => {
    if (map && places && !autocompleteSuggestionRef.current) {
      const { AutocompleteSuggestion, AutocompleteSessionToken, Place } = places;
      placeRef.current = Place;
      autocompleteSuggestionRef.current = AutocompleteSuggestion;
      sessionTokenRef.current = new AutocompleteSessionToken();
    }
  }, [places, map]);

  useEffect(() => recentAddresses.length && setRecent(recentAddresses), [recentAddresses]);

  useEffect(() => {    
    if (saveAddressDialogData) {
      saveAddressDialogDataRef.current = saveAddressDialogData;
    } else {
      setTimeout(() => saveAddressDialogDataRef.current = saveAddressDialogData, 301);
    }
    
  }, [saveAddressDialogData]);

  useEffect(() => {    
    if (handleAddressesModalData) {
      isHandleAddressesModalOpenRef.current = handleAddressesModalData;
    } else {
      setTimeout(() => isHandleAddressesModalOpenRef.current = handleAddressesModalData, 301);
    }
    
  }, [handleAddressesModalData]);

  const onClickout = (e) => {
    if (!saveAddressDialogDataRef.current && !isHandleAddressesModalOpenRef.current) {
      const clicked = e.target;

      let isNotCNFDialog = true;
      if (clicked.classList && typeof clicked.classList.includes === 'function') {
        isNotCNFDialog = !clicked.classList.includes('cnfDialog__conf__btn') && !clicked.classList.includes('cnfDialog__ovrl');
      }
  
      if (
        !isChildOf(clicked, searchRef?.current)
        && clicked !== editRecentsRef?.current
        && clicked !== searchTrigger
        && isNotCNFDialog
      ) {
        (scrollRef?.current || {}).scrollTop = 0;
        setIsSearching(false);
      }
    }
  };

  useEffect(() => {
    if (isSearching) {
      inputFocusOnBtnClick(inputRef?.current);
      if (device !== 'smartphone') {
        window.addEventListener('click', onClickout);
      }
    } else if (device !== 'smartphone') { 
      window.removeEventListener('click', onClickout);
    }
    return () => window.removeEventListener('click', onClickout);
  }, [isSearching, device]);

  const toggleSearch = () => {   
    (scrollRef?.current || {}).scrollTop = 0;
    const newState = !isSearching;
    setIsSearching(newState);
    if (newState) {      
      inputFocusOnBtnClick(inputRef?.current);
    }
  };

  const goToRecent = (recent) => {
    toggleSearch();
    action({ ...recent, town: recent.parent });
  };

  const goToFavorite = ({
    label,
    address,
    number,
    town,
    lat,
    lon,
  }) => {
    toggleSearch();
    
    action({
      name: getName({
        label,
        address,
        number,
        town,
      }),
      parent: getDetails({
        label,
        address,
        number,
        town,
      }),
      location: {
        lat,
        lng: lon,
      },
      address,
      number,
      town,
      favorite: true,
    });
  };

  const updateFavorites = (newList) => {
    setFavorites(newList);
    setItem(LS_FAVORITEADDRESSES, newList);
    globalReducer('setFavoriteAddresses', newList);
  };

  const setSaveAddressDialogOpenRef = () => saveAddressDialogDataRef.current = true;

  const { saveAddress } = useSaveAddress({
    stateActions: {
      getRecents,
      getFavorites,
      setRecent,
      setSaveAddressDialogOpenRef,
    },
    saveAddressSuccessAction: ({ newFavorites }) => setFavorites(newFavorites),
  });

  const manageFavoriteAddresses = () => showHandleAddressesModal({
    type: 'bookmark',
    data: favorites,
    actions: {
      updateFavorites,
      goToAddress: goToFavorite,
    },
    libraries: {
      map,
      core,
      geocoding,
      places,
    },
    recent,
  });

  const removeRecents = (address) => {
    let newRecent = [];
    if (address) {
      newRecent = [...recent].filter(item => `${item.name}-${item.parent}` !== `${address.name}-${address.parent}`);
    }
    setRecent(newRecent);
    setItem(LS_RECENTADDRESSES, newRecent);
    return newRecent;
  };

  const manageRecentAddresses = () => showHandleAddressesModal({
    type: 'history',
    data: recent,
    actions: {
      removeRecents,
      goToAddress: goToRecent,
    },
  });

  const goToLocation = ({
    address,
    location,
    viewport,
    error,
  }) => {
    if (error) {
      setGsLoading(false);
      setGsError(<>Non è possibile trovare la tua posizione al momento.<br />Riprova o usa la barra di ricerca.</>);
    } else {
      const { num, addr, loc } = address;

      const resultObj = {
        name: `${addr}${num ? ` ${num}` : ''}`,
        parent: loc,
        location: JSON.parse(JSON.stringify(location)),
        viewport: JSON.parse(JSON.stringify(viewport)),
        address: addr,
        number: num,
      };
  
      const updatedRecent = saveRecentAddress(resultObj);
      setRecent(updatedRecent);
      toggleSearch();
      setGsLoading(false);
      setQuery('');
      setSuggestions(null);
      action(resultObj);
    }
  };

  const selectSuggestion = async (placeId) => {
    const p = new placeRef.current({
      id: placeId,
      requestedLanguage: 'it',
      sessionToken: sessionTokenRef?.current,
    });

    await p.fetchFields({
      fields: [
        'addressComponents',
        'location',
        'viewport',
      ],
    });
    
    goToLocation({
      address: getAddress(p.addressComponents),
      location: p.location,
      viewport: p.viewport,
    });
  };

  const getSuggestions = async (q) => {
    if ((q || '').length > 2) {
      const request = {
        input: q,
        includedRegionCodes: ['it', 'sm', 'mc'],
        includedPrimaryTypes: ['geocode'],
        sessionToken: sessionTokenRef?.current,
      };

      const { suggestions: acRes } = await autocompleteSuggestionRef.current.fetchAutocompleteSuggestions(request);

      const predictions = (acRes || []).map((s) => {
        const prediction = s.placePrediction;
        if (prediction && prediction.placeId && prediction.text?.text) {
          return {
            place_id: prediction.placeId,
            description: prediction?.text?.text,
          }
        }
        return null;
      }).filter(Boolean);

      setSuggestions(predictions);
    } else {
      setSuggestions(null);
    }
  };    

  const getSuggestionsDB = debounce(getSuggestions, 100);

  const handleInputFocus = () => {
    setIsInpInFocus(true);
    (device !== 'desktop' && !isQBuilder) && lockScreenRef.current.lock();
    gsError && setGsError('');
  };

  const handleInputBlur = () => {
    setIsInpInFocus(false);
    (device !== 'desktop' && !isQBuilder) && setTimeout(() => lockScreenRef.current.unLock(), 250);
  };

  const handleQueryChange = (ev) => {
    const q = ev.target.value;
    gsError && setGsError('');
    setQuery(q);
    getSuggestionsDB(q);
  };

  const eraseQuery = () => {
    setQuery('');
    setSuggestions(null);
    inputFocusOnBtnClick(inputRef?.current);
  };
  
  const doGetUserPosition = () => {
    setGsLoading(true);
    getUserPosition({
      core,
      geocoding,
      action: goToLocation,
    });
    if (geolocationTrackPosition) {
      sleep(300).then(() => {
        trackGTMEvents({
          category: 'Interaction',
          action: 'FindMyLocation',
          position: geolocationTrackPosition,
        }, {}, true);
      });
    }
  };

  if (map) {
    return (
      <div ref={searchRef} className={`csa_mapsearch__bod__map__search${isSearching ? ' searching' : ''}${className ? ` ${className}` : ''}`}>
        <div className="csa_mapsearch__bod__map__search__comp">
          <Grid className="csa_mapsearch__bod__map__search__top">
            <GridItem className={`csa_mapsearch__bod__map__search__inp${isInpInFocus ? ' inFocus' : ''} p--s c-bg--w is-rel`}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Cerca indirizzo"
                className="tp-s--l tp-w--s c-txt--f5 c-bg--w b--0"
                onChange={handleQueryChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={query}
              />              
              <div className={`csa_mapsearch__bod__map__search__inp__erase c-txt--f5${!query.length ? ' is-hidden' : ''}`} onClick={eraseQuery}>
                <Icon name={CloseCirc} />
              </div>
            </GridItem>
            <GridItem behavior="fixed" className="csa_mapsearch__bod__map__search__close pl--m tp-s--s tp-w--m c-txt--f0 is-clickable" onClick={toggleSearch}>
              Annulla
            </GridItem>
          </Grid>
          <div ref={scrollRef} className={`csa_mapsearch__bod__map__search__scroll${suggestions ? ' is-hidden' : ''}${toolControls ? ' csa_mapsearch__bod__map__search__scroll--tools' : ''}`}>
            <div className={`csa_mapsearch__bod__map__search__geo${gsLoading ? ' loading' : ''}`}>
              <Pill
                className="is-rel"
                action={doGetUserPosition}
                leftIcon={<Icon name={mobileOs === 'ios' ? LocalizationIos : Localization} className="mr--xs" width="24px" fill />}
                hasBorder
              >
                <span>Cerca intorno a te</span>
                <Spinner
                  className="csa_mapsearch__bod__map__search__geo__spinner"
                  color="primary"
                  size="m"
                  weight="m"
                  inline
                />
              </Pill>
              {gsError ? <p className="mt--s pr--ms tp-s--xs tp-w--m c-txt--err">{gsError}</p> : null}
            </div>
            <List
              ref={editFavoritesRef}
              list={favorites}
              linkAction={goToFavorite}
              manageAction={manageFavoriteAddresses}
              mobileOs={mobileOs}
              asMobile={device === 'smartphone' || (device === 'tablet' && orientation === 'portrait')}
              orientation={orientation}
            />            
            <List
              ref={editRecentsRef}
              list={recent}
              linkAction={goToRecent}
              manageAction={manageRecentAddresses}
              btnAction={saveAddress}
              mobileOs={mobileOs}
              asMobile={device === 'smartphone' || (device === 'tablet' && orientation === 'portrait')}
              className={favorites.length ? 'mt--s' : ''}
              isRecentList
              showSaveBtn={!isQBuilder || (isQBuilder && user.isAuthenticated)}
            />
          </div>
          <Suggestions
            data={suggestions}
            query={query}
            mobileOs={mobileOs}
            selectSuggestion={selectSuggestion}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default GoogleSearch;

GoogleSearch.propTypes = {
  map: PropTypes.instanceOf(Object),
  mobileOs: PropTypes.string,
  device: PropTypes.string,
  places: PropTypes.instanceOf(Object),
  core: PropTypes.instanceOf(Object),
  geocoding: PropTypes.instanceOf(Object),
  recentAddresses: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  searchTrigger: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  isSearching: PropTypes.bool,
  setIsSearching: PropTypes.func,
  action: PropTypes.func,
  geolocationTrackPosition: PropTypes.string,
  toolControls: PropTypes.bool,
  isQBuilder: PropTypes.bool,
};

GoogleSearch.defaultProps = {
  map: null,
  mobileOs: '',
  device: '',
  places: null,
  core: null,
  geocoding: null,
  recentAddresses: [],
  className: '',
  searchTrigger: null,
  isSearching: false,
  setIsSearching: null,
  action: null,
  geolocationTrackPosition: null,
  toolControls: false,
  isQBuilder: false,
};
