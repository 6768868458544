/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { makeFlip } from '@helpers/DOMHelpers';
import { makeClassName, isFunction } from '@helpers';
import './style.scss';

export default function Shifter({
  children,
  className,
  enabled = true, // Easy way to disable the Shifter
  keepRect = true, // Keep the rect of the original position (avoid jumps on active)
  finalClass, // Class for customizing final step BEFORE transition (good for props which changes position, eg. padding)
  activeClass, // Class for customizing final step AFTER transition (good for props which changes aspect, eg. background)
  animClass = 'is-anim', // Class for customizing animations
  activeBgClass = '', // Class to add to the overlay when the shifter is activated
  qbuilder = false,
  clSearch = false,
  addrList = false,
}) {
  const elRef = useRef(null);
  const elWrapRef = useRef(null);
  const elOvrlRef = useRef(null);
  const [active, setActive] = useState(0);
  const cls = makeClassName(['shifter', className]);

  const actCls = 'shifter--act';
  const actQbCls = 'shifter--act--qb';
  const actCLSCls = 'shifter--act--cls';
  const actADDRCls = 'shifter--act--addr';  

  const updateWrap = ({ width, height } = {}) => {
    if (keepRect) {
      const { style } = elWrapRef.current;
      style.width = width ? `${width}px` : '';
      style.height = height ? `${height}px` : '';
    }
  };

  const updateOverlay = (on) => {
    activeBgClass && setTimeout(() => {
      if (elOvrlRef?.current?.classList) {
        elOvrlRef.current.classList[on ? 'add' : 'remove']('shifter__ovrl--vis');
      }
    });
  };

  const enable = () => enabled && makeFlip(elRef.current, ({ classList, rect }) => {
    updateWrap(rect);
    classList.add(actCls);
    qbuilder && classList.add(actQbCls);
    clSearch && classList.add(actCLSCls);
    addrList && classList.add(actADDRCls);
    finalClass && classList.add(finalClass);
    setActive(1);
    updateOverlay(1);
    setTimeout(() => window.scrollTo(0, 0), 300); // iOS fix
  }, { animClass });

  const disable = () => enabled && makeFlip(elRef.current, ({ classList }) => {
    updateWrap();
    classList.remove(actCls);
    qbuilder && classList.remove(actQbCls);
    clSearch && classList.remove(actCLSCls);
    addrList && classList.remove(actADDRCls);
    finalClass && classList.remove(finalClass);
    setActive(0);
    updateOverlay();
  }, { animClass });

  const handleTransitionEnd = () => {
    activeClass && elRef.current.classList[active ? 'add' : 'remove'](activeClass);
  };

  return (
    <div className={`shifter__wrap${clSearch ? ' shifter__wrap--cls' : ''}`} ref={elWrapRef}>
      <div className={cls} ref={elRef} onTransitionEnd={handleTransitionEnd}>
        { isFunction(children) ? children({ enable, disable, shifted: active }) : children }
      </div>
      <div ref={elOvrlRef} className={`shifter__ovrl is-full-fit is-anim ${!active ? 'is-hidden' : activeBgClass}`} />
    </div>
  );
}
