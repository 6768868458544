import React, {
  useState, useEffect, useRef, memo,
} from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import makeClassName from 'shared-utils/src/makeClassName';
import sleep from 'shared-utils/src/sleep';

import FilterArrowDsk from 'shared-vectors/icons/FilterArrowDsk';
import { Icon } from 'shared-vectors';

import { isChildOf } from '../../../../filters/helpers/DOMHelpers';

import * as h from './helpers';

import './critical.scss';

const Breadcrumb = ({
  data,
  locations,
  isPublisherHP,
  isAgentProPage,
  publisherId,
  isDesktop,
  siteUrl,
}) => {
  const breadcrumbForNoLocs = !locations.length;
  const breadcrumbForRegions = (locations.length === 1 && locations[0].level === 4);
  const breadcrumbForZones = h.areLocationsZonesOfTheSameCity(locations);
  const breadcrumbForCommunes = h.areLocationsCommunesOfTheSameProvince(locations);
  const breadcrumbForNonAdminZones = (locations.length === 1 && locations[0].level === 130);
  const breadcrumbDropDownItems = data?.dropdown?.items || [];

  if (
    publisherId
    || (
      breadcrumbDropDownItems.length > 0
      && (
        (
          locations.length === 1
          && locations[0].level !== 999
        )
        || breadcrumbForZones
        || breadcrumbForCommunes
      ) || breadcrumbForRegions
    )
    || breadcrumbForNoLocs
    || breadcrumbForNonAdminZones
  ) {
    
    const {
      links: {
        home,
        hub,
        root,
        ptype,
        licenseTypeGroup,
        toRenovate,
        sort,
        filter,
        state,
        province,
        propertyTypeGroup,
        town,
        zone,
        metroline,
        nonadminzone,
        button,
        parent,
        publisher,
        agentPro,
      },
      dropdown: {
        items,
        event,
      },
    } = data;
    // safeIsDesktop - il typeof setta la variabile a true in modo da non far stampare i link only mobile della dropdow in fase di SSR
    const safeIsDesktop = typeof isDesktop !== 'string' && isDesktop;
    const btn = useRef(null);
    const dd = useRef(null);
    const close = useRef(null);
    const [dropDownOpen, setDropdownOpen] = useState(false);
    const [dropDownCoords, setDropdownCoords] = useState(null);
    const ddClickOut = (ev) => {
      const tgt = ev.target;
      if (
        tgt !== btn.current
        && tgt !== close.current
        && !isChildOf(tgt, btn.current)
        && !isChildOf(tgt, dd.current)
      ) {
        setDropdownOpen(!dropDownOpen);
      }
    };
    useEffect(
      () => {
        if (dropDownOpen) {
          document.addEventListener('click', ddClickOut);
        } else {
          document.removeEventListener('click', ddClickOut);
        }
        return () => document.removeEventListener('click', ddClickOut);
      }, [dropDownOpen],
    );
    useEffect(
      () => {
        if (btn.current && dd.current) {
          if (isDesktop) {
            setDropdownCoords(Math.round(btn.current.getBoundingClientRect().left - dd.current.getBoundingClientRect().left) + 15);
          } else {
            setDropdownCoords(null);
          }
        }
      }, [isDesktop, button], // da aggiornare passando da desktop a mobile e nel caso in cui il valore del bottone cambi
    );
    const closeAction = () => setDropdownOpen(!dropDownOpen);
    const trackClick = (ev) => {
      if (publisher || agentPro) {
        return;
      }
      const tgt = ev.target;
      const label = tgt.getAttribute('data-event')
        ? tgt.getAttribute('data-event')
        : tgt.parentNode.getAttribute('data-event');
      trackGTMEvents({
        category: 'Link',
        action: 'Breadcrumbs',
        label,
      }, null, true);
    };

    const btnAction = (ev) => {
      setDropdownOpen(!dropDownOpen);
      sleep().then(() => trackClick(ev));
    };

    const linkCls = makeClassName([
      'srpBc__txtitm',
      'tp-w--s',
      'c-txt--f2',
      'tp--u',
    ]);

    const createLink = ({
      link,
      showOnMob = false,
      isPubParent = false,
      shorten = false,
    }) => (
      
      <>
        <a
          href={`${siteUrl}${link.href}`}
          title={link.title}
          className={`srpBc__lnk${showOnMob ? ' srpBc__lnk--twn' : ''} ${linkCls}${shorten ? ' tp--ell' : ''}`}
          data-event={link.event}
          onClick={trackClick}
        >
          {link.value}
        </a>
        <span className={`srpBc__sep${showOnMob ? ' srpBc__sep--twn' : ''}${isPubParent ? ' srpBc--nomob' : ''} c-txt--secondary`}>
          <Icon
            name={FilterArrowDsk}
            width="12px"
            height="12px"
            className="srpBc__sep__icon rotate--270"
            color="f1"
          />
        </span>
      </>
    );

    const createButton = () => {
      const {
        main, additional, hasNoLocality, event: evt,
      } = button;

      const more = additional
        ? <>&nbsp;<span className="c-txt--secondary">{additional}</span></>
        : null;
      const cssClss = `srpBc__btn${dropDownOpen ? ' open' : ''} ${linkCls}${hasNoLocality || zone ? '' : ' tp--ell'}`;
      return (
        <button
          ref={btn}
          type="button"
          className={cssClss}
          data-event={evt}
          onClick={btnAction}
        >
          <><span className="srpBc__btn__txt tp--ell">{main}</span>{more}</>
        </button>
      );
    };
    const createDDLink = (link, padding, evt) => (
      <a
        key={link.value}
        href={link.href}
        title={link.title}
        className={`srpBc__dd__lnk${isDesktop ? ' pl--m pr--m' : ''}  tp-s--s ${link.isSelected ? 'tp-w--l' : 'tp-w--s'} c-txt--secondary`}
        data-event={evt || link.event}
        data-count={link.count || 'NUOVA RICERCA'}
        onClick={trackClick}
        style={padding ? { padding: `0 ${padding}px` } : {}}
      >
        <span className="srpBc__dd__lnk--txt tp--ell">
          {link?.value?.indexOf('(provincia)') > -1 ? `Provincia di ${link?.value?.replace(' (provincia)', '')}` : link?.value}
        </span>
      </a>
    );
    let ddLinksPadding = null;
    if (!isDesktop) {
      ddLinksPadding = 20;
      if (town) {
        if (zone) {
          ddLinksPadding = 40;
        } else {
          ddLinksPadding = 30;
        }
      }
    }

    return (
      <div className={`srpBc${(isPublisherHP) ? ' srpBc--pub' : `${isAgentProPage ? ' srpBc--pub srpBc--agntPro' : ''}`}${dropDownOpen ? ' srpBc--open' : ''}`}>
        {home && createLink({ link: home })}
        {hub && createLink({ link: hub })}
        {root && createLink({ link: root })}
        {state && createLink({ link: state })}
        {province && createLink({ link: province })}
        {propertyTypeGroup && createLink({ link: propertyTypeGroup })}
        {town && createLink({ link: town, showOnMob: true })}
        {nonadminzone && createLink({ link: nonadminzone })}
        {ptype && createLink({ link: ptype })}
        {licenseTypeGroup && createLink({ link: licenseTypeGroup })}
        {toRenovate && createLink({ link: toRenovate, shorten: true })}
        {filter && createLink({ link: filter, shorten: true })}
        {sort && createLink({ link: sort, shorten: true })}
        {zone && createLink({ link: zone })}
        {metroline && createLink({ link: metroline })}
        {parent && createLink({
          link: parent,
          showOnMob: true,
          isPubParent: true,
        })}
        {button.isButton ? createButton(button) : <p className="tp-s--xs tp-w--m tp--ell c-txt--f2">{button.main}</p>}
        {publisher && agentPro
          ? createLink({
            link: publisher,
            showOnMob: true,
            isPubParent: true,
          }) : (
            <p className={`${parent ? 'srpBc--nomob ' : ''} srpBc--padt tp-s--xs tp-w--m tp--ell c-txt--f2`}>
              {publisher}
            </p>
          )
        }
        {agentPro && <p className="tp-s--xs tp-w--m tp--ell c-txt--f2 srpBc--nomob">{agentPro}</p>}
        {
          button.isButton
          && (
            <div
              ref={dd}
              className={`srpBc__dd${dropDownOpen ? ` srpBc__dd--open${isDesktop ? ' b--s' : ''}` : ''} c-bg--w`}
              style={dropDownCoords ? { left: `${dropDownCoords}px` } : null}
            >
              <div className="srpBc__dd__lst">
                {!safeIsDesktop && home && createDDLink(home, '')}
                {!safeIsDesktop && province && createDDLink(province, 10)}
                {!safeIsDesktop && town && createDDLink(town, 20)}
                {!safeIsDesktop && zone && createDDLink(zone, 30)}
                {!safeIsDesktop && nonadminzone && createDDLink(nonadminzone, 10)}
                {items.map(link => createDDLink(link, ddLinksPadding, event))}
              </div>
              {!safeIsDesktop
                && (
                  <button
                    ref={close}
                    type="button"
                    aria-label="Chiudi la lista delle località"
                    className="srpBc__dd__cls tp-s--xs tp-w--m c-txt--secondary c-bg--w ta--c"
                    onClick={closeAction}
                  >
                    chiudi
                  </button>
                )}
            </div>
          )
        }
        <div className={`srpBc__ovrl${dropDownOpen ? ' srpBc__ovrl--open' : ''}`} />
      </div>
    );
  }
  return null;
};
Breadcrumb.propTypes = {
  data: PropTypes.instanceOf(Object),
  locations: PropTypes.instanceOf(Array),
  isPublisherHP: PropTypes.bool,
  isAgentProPage: PropTypes.bool,
  publisherId: PropTypes.number,
  isDesktop: PropTypes.bool,
  siteUrl: PropTypes.string,
};
Breadcrumb.defaultProps = {
  data: {},
  locations: [],
  isPublisherHP: false,
  isAgentProPage: false,
  publisherId: null,
  isDesktop: false,
  siteUrl: 'https://www.casa.it',
};
export default memo(Breadcrumb);
