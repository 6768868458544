export const getAddressNumber = (data) => {
  const parts = data.name.split(' ');
  const hasNumber = /^\d/.test(parts[parts.length - 1]);
  let number = '';
  if (hasNumber) {
    number = parts.pop();
  }
  const address = parts.join(' ');

  return { address, number };
};

export default (place) => {
  let num = '';
  let addr = '';
  let loc = '';
  let addrIndex = 0;
  let locIndex = 1;

  if (place && place[0]) {
    const [first] = place;
    if (first.types && first.types[0]) {
      const [type] = first.types;
      if (type === 'street_number') {
        num = first.shortText;
        addrIndex = 1;
        locIndex = 2;
      } else if (type === 'plus_code' || type === 'establishment' || type === 'park' || type === 'point_of_interest') {  
        addrIndex = 1;
        locIndex = 2;
      } else if (type === 'locality') {
        addr = first.longText;
        locIndex = 2;
      } else {
        addr = first.longText;
      }
    }
    if (addrIndex && place[addrIndex]) {
      addr = place[addrIndex].longText;
    }
    if (place[locIndex]) {
      loc = place[locIndex].shortText;
    }
  }

  return {
    num,
    addr,
    loc,
  };
};
