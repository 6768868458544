import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ContentAsync from './lazy';

import './style.scss';

const SaveAddressDialogLoader = ({ isOpen, close }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isOpen && !loaded) {
      setLoaded(true);
    }
  }, [isOpen, loaded]);

  return loaded ? (
    <ContentAsync
      isOpen={isOpen}
      close={close}
    />
  ) : null;
};

export default SaveAddressDialogLoader;

SaveAddressDialogLoader.propTypes = {
  isOpen: PropTypes.instanceOf(Object),
  close: PropTypes.func,
};

SaveAddressDialogLoader.defaultProps = {
  isOpen: null,
  close: null,
};
