/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

const highlightValue = (str = '', qr) => {
  const queryLow = qr.toLowerCase().trim();
  const strLow = str.toLowerCase();
  const start = strLow.indexOf(queryLow);
  const end = start + qr.length;
  return start >= 0 ? (
    <>
      {str.substr(0, start)}
      <span className="tp-w--m">
        {str.substr(start, qr.length)}
      </span>
      {str.substr(end)}
    </>
  ) : (
    str
  );
};

const keyCodeMap = {
  40: 'down',
  38: 'up',
  13: 'enter',
};

const Suggestions = ({
  data,
  query,
  mobileOs,
  selectSuggestion,
}) => {
  const [high, setHigh] = useState(0);

  const handleKeydown = useCallback((evt) => {
    const pressed = keyCodeMap[evt.keyCode];
    if (pressed) {
      switch (pressed) {
        case 'down': {
          const next = high === 4 ? 0 : high + 1;
          setHigh(next);
          break;
        } 
        case 'up': {
          const next = high === 0 ? 4 : high - 1;
          setHigh(next);
          break;
        }
        case 'enter':
          selectSuggestion(data[high].place_id);
          break;
        default:
      }      
    }
  }, [data, high, setHigh]);

  useEffect(() => {
    if (data && data.length) {
      window.addEventListener('keydown', handleKeydown);
    } else {
      window.removeEventListener('keydown', handleKeydown);
    }
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [data, handleKeydown]);

  if (data) {
    if (!data.length) {
      return <p className="pt--ml pb--m tp-s--s tp-w--m tp-a--c">Indirizzo non trovato, riprova.</p>;
    }    
    return (
      <>
        <ul className={`mt--${!mobileOs ? 's' : 'ms'}`}>
          {data.map((sugg, index) => (
            <li
              key={sugg.place_id}
              className={`csa_mapsearch__bod__map__search__sugg${(!mobileOs && index === data.length - 1) ? ' last' : ''}${(!mobileOs && index === high) ? ' high' : ''} pt--s pr--m pb--s pl--m tp-s--m c-txt--f0 c-bg--w b${!mobileOs ? 't' : 'b'}--s tp--ell is-rel is-clickable`}
              onMouseEnter={() => setHigh(index)}
              onClick={() => selectSuggestion(sugg.place_id)}
            >
              {highlightValue(sugg.description, query)}
            </li>
          ))}
        </ul>
        {mobileOs ? <div className="csa_mapsearch__bod__map__search__powered mt--s" /> : null}        
      </>
    ); 
  }
  return null;    
};

export default Suggestions;

Suggestions.propTypes = {
  data: PropTypes.instanceOf(Array),
  query: PropTypes.string,
  mobileOs: PropTypes.string,
  selectSuggestion: PropTypes.func,
};

Suggestions.defaultProps = {
  data: null,
  query: '',
  mobileOs: '',
  selectSuggestion: () => {},
};
