import { canUseDOM } from '../../components';

export const FETCH_REMOVE_MAIL_AND_CONSENTS_CLIENT = 'FETCH_REMOVE_MAIL_AND_CONSENTS_CLIENT';
export const FETCH_REMOVE_MAIL_AND_CONSENTS = 'FETCH_REMOVE_MAIL_AND_CONSENTS';
export const FETCH_REMOVE_MAIL_AND_CONSENTS_START = 'FETCH_REMOVE_MAIL_AND_CONSENTS_START';
export const FETCH_REMOVE_MAIL_AND_CONSENTS_SUCCESS = 'FETCH_REMOVE_MAIL_AND_CONSENTS_SUCCESS';
export const FETCH_REMOVE_MAIL_AND_CONSENTS_ERROR = 'FETCH_REMOVE_MAIL_AND_CONSENTS_ERROR';

const fetchFn = (fetch, url, verb, params) => fetch(url, {
  method: verb,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Connection: 'keep-alive',
  },
  body: JSON.stringify(params),
  credentials: 'same-origin',
});

export function fetchRemoveMailAndConsents(params) {
  if (canUseDOM) {
    return () => ({
      type: FETCH_REMOVE_MAIL_AND_CONSENTS_CLIENT,
    });
  }
  const url = '/portal-srp/api/v1/remove-mail-and-consents/';
  const verb = 'POST';
  const body = {
    site: params?.apireq?.site, 
    domain: params.domain, 
    locale: params.locale, 
    apireq: params.apireq, 
    baseUri: params.uri,
  };
  return ({ fetch }) => ({
    type: FETCH_REMOVE_MAIL_AND_CONSENTS,
    payload: {
      promise: fetchFn(fetch, url, verb, body)
        .then(response => response.json())
        .catch(err => console.error('Error fetch remove mail and consents', err)),
    },
  });
}
