import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import Bookmark from 'shared-vectors/icons/Bookmark';
import Chevron from 'shared-vectors/icons/DsChevron16';
import { Icon } from 'shared-vectors';

import { getName, getDetails } from '../../../helpers/getAddressStrings';

const GSList = forwardRef(({
  list = [],
  linkAction = null,
  btnAction = null,
  manageAction = null,
  mobileOs = '',
  asMobile = false,
  className = '',
  isRecentList,
  showSaveBtn,
}, ref) => {
  if (!list || !list.length) {
    return null;
  }

  const safeAction = (action, params = null) => action && action(params);

  return (
    <div className={`csa_mapsearch__bod__map__search__rec${className ? ` ${className}` : ''}`}>
      <Grid align="center" className="csa_mapsearch__bod__map__search__list--tit pr--ms pl--ms">
        <GridItem className="csa_mapsearch__bod__map__search__list--tit--text tp-s--xs tp-w--m c-txt--f0">
          {isRecentList ? 'RECENTI' : 'PREFERITI'}
        </GridItem>
        <div
          ref={ref}
          className={`tp-s--s tp-w--m c-txt--${asMobile ? 'f0' : 'f5'} csa_mapsearch__bod__map__search__txtbtn is-rel is-clickable`}
          onClick={() => safeAction(manageAction)}
        >
          <span>{isRecentList ? 'Elimina' : 'Gestisci'}</span>
          <Icon name={Chevron} width="16px" fill />
        </div>
      </Grid>
      <ul className="csa_mapsearch__bod__map__search__list mt--s bt--s">
        {list.map((item, index) => {
          const name = getName(item, isRecentList);
          const details = getDetails(item, isRecentList);
          return (
            <li
              key={`${name}-${details}`}
              className={`csa_mapsearch__bod__map__search__list--item${(!mobileOs && index === 0) ? ' first' : ''}${(!mobileOs && index === list.length - 1) ? ' last' : ''} pt--xs pr--ms pb--xs pl--ms tp-s--s c-txt--secondary c-bg--w bb--s tp--ell is-rel is-clickable`}              
            >
              <Grid align="center">
                <GridItem className="csa_mapsearch__bod__map__search__list--item--txt" onClick={() => safeAction(linkAction, item)}>
                  <p className="csa_mapsearch__bod__map__search__list--item--tit tp-w--m tp--ell">
                    {name}
                  </p>
                  <p className="tp--ell">
                    {details}
                  </p>
                </GridItem>
                {(isRecentList && showSaveBtn) ? (
                  <GridItem behavior="fixed">
                    <button className="csa_mapsearch__bod__map__search__list--add c-bg--tr b--0 is-clickable" onClick={() => safeAction(btnAction, item)}>
                      <Icon name={Bookmark} size="m" fill />
                    </button>
                  </GridItem>
                ) : null}
              </Grid>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

export default GSList;

GSList.propTypes = {
  list: PropTypes.instanceOf(Array),
  linkAction: PropTypes.func,
  btnAction: PropTypes.func,
  manageAction: PropTypes.func,
  mobileOs: PropTypes.string,
  asMobile: PropTypes.bool,
  className: PropTypes.string,
  isRecentList: PropTypes.bool,
  showSaveBtn: PropTypes.bool,
};

GSList.defaultProps = {
  list: [],
  linkAction: null,
  btnAction: null,
  manageAction: null,
  mobileOs: '',
  asMobile: false,
  className: '',
  isRecentList: false,
  showSaveBtn: false,
};