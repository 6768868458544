import React from 'react';
import PropTypes from 'prop-types';

import './critical.scss';

const Icon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.25 4C4.11193 4 4 4.11193 4 4.25L4 11.75C4 11.8881 4.11193 12 4.25 12L11.75 12C11.8881 12 12 11.8881 12 11.75L12 9.74946C12 9.33525 12.3358 8.99946 12.75 8.99946C13.1642 8.99946 13.5 9.33525 13.5 9.74946L13.5 11.75C13.5 12.7165 12.7165 13.5 11.75 13.5L4.25 13.5C3.2835 13.5 2.5 12.7165 2.5 11.75L2.5 4.25C2.5 3.2835 3.2835 2.5 4.25 2.5L6.25 2.5C6.66421 2.5 7 2.83579 7 3.25C7 3.66421 6.66421 4 6.25 4L4.25 4ZM9.72727 4C9.31306 4 8.97727 3.66421 8.97727 3.25C8.97727 2.83579 9.31306 2.5 9.72727 2.5L12.75 2.5C13.1642 2.5 13.5 2.83579 13.5 3.25V6.27273C13.5 6.68694 13.1642 7.02273 12.75 7.02273C12.3358 7.02273 12 6.68694 12 6.27273V5.06066L8.53033 8.53033C8.23744 8.82322 7.76256 8.82322 7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967L10.9393 4H9.72727Z" fill="#505A65" />
  </svg>
);

export default function SortTypeLink({ hide = false, listingEnd = false, env = 'prod' }) {
  return (
    <div className={`mb--m lnk_sort${listingEnd ? ' moreMobSpace' : ''}`} style={hide ? { opacity: 0, pointerEvents: 'none' } : null}>
      <a
        href={`${env === 'prod' ? 'https://www.casa.it' : 'https://www.dev.casa.it'}/casait/come-ordiniamo-i-risultati/`}
        title="Scopri come ordiniamo i risultati"
        className="lnk_sort__a tp-w--m c-txt--f5"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="mr--xs">Come ordiniamo i risultati</span> <Icon />
      </a>
    </div>
  );
}

SortTypeLink.propTypes = {
  hide: PropTypes.bool,
  listingEnd: PropTypes.bool,
  env: PropTypes.string,
};

SortTypeLink.defaultProps = {
  hide: false,
  listingEnd: false,
  env: 'prod',
};
