import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import {
  normalizeSRPUrl,
} from '../../../../common/lib/parseUriHelper';

const propTypes = {
  seoData: PropTypes.instanceOf(Object),
  domain: PropTypes.string,
  numResults: PropTypes.number,
  uri: PropTypes.string,
  publisher: PropTypes.instanceOf(Object),
  isPublisherHP: PropTypes.bool,
  isPublisherPage: PropTypes.bool,
  homeRent: PropTypes.bool,
  paginator: PropTypes.instanceOf(Object),
  preloadImg: PropTypes.string,
};

const defaultProps = {
  seoData: {},
  domain: '',
  numResults: 0,
  uri: '',
  publisher: {},
  isPublisherHP: false,
  isPublisherPage: false,
  homeRent: false,
  paginator: null,
  preloadImg: null,
};

const MetaInfo = ({
  seoData,
  domain,
  numResults,
  uri,
  publisher,
  isPublisherHP,
  isPublisherPage,
  homeRent,
  paginator,
  preloadImg,
}) => {
  const {
    isAgentPro = false,
  } = publisher || {};
  let title = seoData && seoData.seo ? seoData.seo.title : '';
  let description = seoData && seoData.seo && seoData.seo.meta && seoData.seo.meta.description ? seoData.seo.meta.description.content : '';

  let publisherCanonical = null;
  if (isPublisherPage && !isAgentPro) {

    const {
      publisherName,
      publisherAddress: {
        street,
        town,
      },
    } = publisher;

    const cityString = town
      ? ` a ${town}`
      : '';
    const streetString = street
      ? ` in ${street}`
      : '';

    const numResultsToUSe = numResults > 0 ? `i ${numResults} annunci di immobili` : 'tutti gli annunci di immobili';
    title = `${publisherName}${cityString} | Casa.it`;
    description = `Agenzia ${publisherName}${cityString}${streetString}. Scopri ${numResultsToUSe} presenti su Casa.it!`;
    const forCanonical = uri.split('?').length > 1 ? `${uri.split('?')[0]}/` : uri;
    if (isPublisherHP) {
      publisherCanonical = `https://www.${domain}${forCanonical}`;
    }
  } else if (isPublisherPage && isAgentPro) {

    const { publisherParent: { name: parentName } = {} } = publisher;
    const numResultsToUSe = numResults > 0 ? `i ${numResults} annunci di immobili` : 'tutti gli annunci di immobili';
    title = `${parentName} - ${publisher.publisherName} | Casa.it`;
    description = `Scopri ${numResultsToUSe} dell'agente ${publisher.publisherName}, dell'agenzia ${parentName} e contattalo online su Casa.it!`;
    const forCanonical = uri.split('?').length > 1 ? `${uri.split('?')[0]}/` : uri;
    if (isPublisherHP) {
      publisherCanonical = `https://www.${domain}${forCanonical}`;
    }
  }
  const currentPage = paginator && paginator.currentPage
    ? paginator.currentPage
    : 1;

  const finalLangSuffix = null;
  let links = [];

  if (
    seoData.urls.hreflang
    && currentPage <= 30
  ) {
    const hrefLangLinks = publisherCanonical
      ? [{
        key: 'hrefLang',
        rel: 'alternate',
        href: publisherCanonical,
        hreflang: 'it',
      }] : Object.keys(seoData.urls.hreflang).map(
        (key, index) => {
          if (
            seoData.robots_index
            && seoData.robots_index === true
            && seoData.urls.hreflang[key] !== '/realestate/'
          ) {
            return {
              key: `hrefLang-${index}`,
              rel: 'alternate',
              href: `https://www.${domain}${seoData.urls.hreflang[key]}`,
              hreflang: finalLangSuffix ? `${key}-${finalLangSuffix}` : key,
            };
          }
          return null;
        },
      ).filter(Boolean);
    links = [...hrefLangLinks];
  }

  if (preloadImg) {
    links.push({
      rel: 'preload',
      as: 'image',
      href: preloadImg,
      key: 'preloadImg',
    });
  }

  let robots = 'noindex, follow';
  if (seoData.robots_index && seoData.robots_index === true) {
    robots = 'All';
    if (publisherCanonical) {
      links.push({
        rel: 'canonical',
        href: normalizeSRPUrl(publisherCanonical),
      });
    } else if (seoData.urls.canonical) {
      let canonical = normalizeSRPUrl(`https://www.${domain}${seoData.urls.canonical}`);
      if (
        typeof homeRent !== 'undefined'
        && homeRent !== null
        && homeRent
        && canonical === 'https://www.casa.it/'
      ) {
        canonical = 'https://www.casa.it/liste/affitto/';
      }
      links.push({
        rel: 'canonical',
        href: canonical,
      });
    }
  }
  if (paginator) {
    if (paginator.prevLink && paginator.prevLink.href) {
      links.push({
        rel: 'prev',
        href: `https://www.casa.it${paginator.prevLink.href}`,
      });
    }
    if (
      paginator.nextLink
      && paginator.nextLink.href
      && !isPublisherHP
    ) {
      links.push({
        rel: 'next',
        href: `https://www.casa.it${paginator.nextLink.href}`,
      });
    }
  }
  if (seoData?.seo?.meta?.robots?.content === 'noindex, nofollow') {
    robots = seoData.seo.meta.robots.content;
  }

  const metasArray = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'robots',
      content: robots,
    },
  ];
  if (
    'appstore_meta' in seoData
    && seoData.appstore_meta.name
    && seoData.appstore_meta.content
  ) {
    metasArray.push(seoData.appstore_meta);
  }

  return (
    <Helmet
      title={title}
      meta={metasArray}
      link={links}
    />
  );
};
MetaInfo.propTypes = propTypes;
MetaInfo.defaultProps = defaultProps;
export default MetaInfo;
