import { setItem, getItem } from 'shared-utils/src/localStorage';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import { useCtxGlobal } from '@client/app/Container';

import { LS_RECENTADDRESSES, LS_FAVORITEADDRESSES } from '../constants';

export default ({
  stateActions = {},
  saveAddressSuccessAction = null,
}) => {
  const {
    globalReducer,
    showSaveAddressDialog,
    user,
    logIn,
    showSynchingDialog,
  } = useCtxGlobal();

  const getEvtPosition = () => {
    if (stateActions.setSaveAddressDialogOpenRef) {
      return 'AddressList';
    }
    return 'Map_Tooltip';
  };

  const saveAddressSuccess = (address, favorite) => {   
    let recent = [];
    let favorites = [];

    if (stateActions.getRecents) {
      recent = stateActions.getRecents();
    } else {
      recent = getItem(LS_RECENTADDRESSES);
    }

    if (stateActions.getFavorites) {
      favorites = stateActions.getFavorites();
    }

    const newRecent = [...recent].filter(item => `${item.name}-${item.parent}` !== `${address.name}-${address.parent}`);

    if (stateActions.setRecent) {
      stateActions.setRecent(newRecent);
    }

    setItem(LS_RECENTADDRESSES, newRecent);

    // add favorite to favorites and to LS favorites
    const favs =  (favorites || []).length ? favorites : getItem(LS_FAVORITEADDRESSES);
    const newFavorites = [...favorite, ...favs];
    setItem(LS_FAVORITEADDRESSES, newFavorites);
    globalReducer('setFavoriteAddresses', newFavorites);

    trackGTMEvents({
      category: 'Interaction',
      action: 'SaveAddress',
      position: getEvtPosition(),
    }, null, true);

    if (saveAddressSuccessAction) {
      saveAddressSuccessAction({ newFavorites, favorite });
    }
  };

  const doSaveAddress = (address) => {
    trackGTMEvents({
      action: 'OpenSaveAddressModal',
      position: getEvtPosition(),
    }, null, true);
    showSaveAddressDialog({
      address,
      callback: saveAddressSuccess,
    });
  };

  const saveAddress = (address) => {
    if (user.isAuthenticated) {
      doSaveAddress(address);
    } else {
      if (stateActions.setSaveAddressDialogOpenRef) {
        stateActions.setSaveAddressDialogOpenRef();
      }
      logIn(
        () => showSynchingDialog({ afterSynchFn: () => doSaveAddress(address) }),
        'SaveAddress',
        getEvtPosition(),
      );
    }
  };

  return { saveAddress };
};
