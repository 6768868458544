const removeShape = (shape) => {
  if (shape) {
    if (Array.isArray(shape)) {
      shape.map(removeShape);
    } else {
      shape.setMap(null);
    }
  }
};

export default ({ drawingData, fromSSearch = false }) => {
  const { activeShapes, activeShapesToRemove} = drawingData;
  const shapes = fromSSearch ? activeShapesToRemove : activeShapes;
  shapes.map(removeShape);
};
