import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'shared-components/components/Spinner';

import getUserPosition from '../../../helpers/getUserPosition';
import saveRecentAddress from '../../../helpers/saveRecentAddress';

const GeoLocationBtn = ({
  action,
  className,
  childrenClassName,
  core,
  geocoding,
  gSearchError,
  resetGSearchError,
  children,
  isSearchAroundYou,
}) => {
  const [loading, setLoading] = useState(false);
  /* 
    in questo modo quando il componente viene smontato (utente clicca il bottone,
    ma chiude la modalità disegno prima che la reverse abbia finito)
    la reverse non può generare uina shape imprevista
  */
  const internalAction = useRef(null);  
  useEffect(() => {
    internalAction.current = action;
    return () => { internalAction.current = null; };
  }, []);
  /** */

  const getSearchLocation = ({
    address,
    location,
    viewport,
    error,
  }) => {
    setLoading(false);
    if (error) {
      if (internalAction.current) {
        internalAction.current({ error });
      }
    } else {
      const { num, addr, loc } = address;

      const resultObj = {
        name: `${addr}${num ? ` ${num}` : ''}`,
        parent: loc,
        location: JSON.parse(JSON.stringify(location)),
        viewport: JSON.parse(JSON.stringify(viewport)),
        address: addr,
        number: num,
      };
  
      if (internalAction.current) {
        saveRecentAddress(resultObj);
        internalAction.current(resultObj);
      }
    }
  };

  const doGetUserPosition = (e) => {
    e.stopPropagation();
    if (gSearchError && resetGSearchError) {
      resetGSearchError();
    }
    setLoading(true);
    getUserPosition({
      core,
      geocoding,
      action: getSearchLocation,
    });
  };

  return (
    <>
      <div className={`${className}${loading ? ' loading' : ''} is-rel`} onClick={doGetUserPosition}>
        {children}
        <Spinner
          className={`${childrenClassName}__spinner`}
          color={isSearchAroundYou ? 'w' : 'primary'} 
          size="m"
          weight="m"
          inline
        />
      </div>
      {gSearchError ? (
        <p className={`${childrenClassName}__err mt--s pl--xs tp-s--xs tp-w--m c-txt--err`}>
          {gSearchError}
        </p>
      ) : null}
    </>
  );
};

export default GeoLocationBtn;

GeoLocationBtn.propTypes = {
  isSearchAroundYou: PropTypes.bool,
  action: PropTypes.func,
  className: PropTypes.string,
  childrenClassName: PropTypes.string,
  core: PropTypes.instanceOf(Object),
  geocoding: PropTypes.instanceOf(Object),
  gSearchError: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
  resetGSearchError: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

GeoLocationBtn.defaultProps = {
  isSearchAroundYou: false,
  action: null,
  className: '',
  childrenClassName: '',
  core: null,
  geocoding: null,
  gSearchError: null,
  resetGSearchError: null,
  children: null,
};
