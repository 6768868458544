import { NO_LABEL } from '../constants';

export const isNonValidLabel = (label) => [
  'Punto condiviso',
  '-',
  NO_LABEL,
].includes(label);

export const getName = (item, isRecentList = false) => {
  if (isRecentList) {
    return item.name;
  }
  return isNonValidLabel(item.label)
    ? `${item.address}${item.number ? ` ${item.number}` : ''}`
    : item.label;
};

export const getDetails = (item, isRecentList = false) => {
  if (isRecentList) {
    return item.parent;
  }
  return isNonValidLabel(item.label)
    ? item.town
    : `${item.address}${item.number ? ` ${item.number}` : ''}, ${item.town}`;
};