import { setItem, getItem } from 'shared-utils/src/localStorage';

import { useCtxGlobal } from '@client/app/Container';

import { LS_RECENTADDRESSES, LS_FAVORITEADDRESSES } from '../constants';

import { getName, getDetails } from './getAddressStrings';

export default ({
  map = null,
  libraries = null,
  editAddressSuccessAction,
}) => {
  const {
    globalReducer,
    showHandleAddressesModal
  } = useCtxGlobal();

  const updateFavorites = (favorites, fav, deleted = false, changedPosition = false) => {
    setItem(LS_FAVORITEADDRESSES, favorites);
    globalReducer('setFavoriteAddresses', favorites);

    const {
      label,
      address,
      number,
      town,
      lat,
      lon,
    } = fav;

    const pinDefaultConfiguration = {
      isPin: true,
      lat,
      lon,
      favorite: !deleted,
      uKey: `pin_${lat},${lon}`,
    };
    
    const pinConfiguration = !deleted ? {
      ...pinDefaultConfiguration,
      name: getName({
        label,
        address,
        number,
        town,
      }),
      parent: getDetails({
        label,
        address,
        number,
        town,
      }),
    } : {
      ...pinDefaultConfiguration,
      name: `${address}${number ? ` ${number}` : ''}`,
      parent: town,
    };

    editAddressSuccessAction({ pinConfiguration, changedPosition });
  };

  const manageFavoriteAddresses = (address) => {
    const {
      core,
      geocoding,
      places,
    } = libraries;

    const favorites = getItem(LS_FAVORITEADDRESSES);
    const favorite = favorites.filter(addr => addr.lat === address.lat && addr.lon === address.lon)[0];

    showHandleAddressesModal({
      type: 'edit-bookmark',
      data: favorites,
      toedit: favorite,
      actions: { updateFavorites },
      libraries: {
        map,
        core,
        geocoding,
        places,
      },
      recent: getItem(LS_RECENTADDRESSES),
    });
  };

  return { manageFavoriteAddresses };
};
